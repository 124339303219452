<template>
  <v-app-bar id="main-bar" app>
    <v-app-bar-nav-icon
      v-if="$vuetify.breakpoint.mobile && !!currentUser"
      @click="toggleDrawer()"
    ></v-app-bar-nav-icon>

    <v-container class="bar-container" fluid>
      <v-row align="center">
        <v-toolbar-title>
          <router-link :to="{ name: 'dashboard' }">
            <img :height="40" class="logo ml-3" src="@assets/images/logo.svg" />
          </router-link>
        </v-toolbar-title>

        <adf-app-bar-search
          v-if="!$vuetify.breakpoint.mobile"
        ></adf-app-bar-search>

        <div class="flex-grow-1"></div>

        <span class="mr-3">

          <v-btn
                  v-if="currentUserHasRoles(['worship_leader', 'songs_manager'])"
                  :to="{ name: 'songs_new' }"
                  class="mr-3"
                  small
                  color="primary"
                  depressed
                  rounded
          >
            CHANT
            <v-icon small>mdi-plus</v-icon>
          </v-btn>

          <adf-tuner-show v-if="currentUser">
            <template #activator="{onClick}">
              <v-hover v-slot="{ hover }" disabled>
                <v-btn class="tuner-btn px-5 mr-1" icon small @click="onClick">
                  <v-img
                    :src="hover ? tuningForkHover : tuningFork"
                    class="tuner-icon"
                    max-height="24"
                    max-width="24"
                  />
                </v-btn>
              </v-hover>
            </template>
          </adf-tuner-show>

          <v-btn
            v-if="currentUser"
            :icon="$vuetify.breakpoint.mobile"
            class="pr-0"
            elevation="0"
            rounded
            @click="goToProfile"
          >
            <span
              v-if="!$vuetify.breakpoint.mobile"
              class="d-none d-sm-inline pr-3"
              >{{ currentUser.firstName }}</span
            >

            <v-avatar class="white" size="40">
              <v-icon v-if="!currentUser.avatar.thumb" dark>
                mdi-account-circle
              </v-icon>
              <img
                v-else
                :alt="`Avatar de ${currentUser.fullName}`"
                :src="currentUser.avatar.thumb"
              />
            </v-avatar>
          </v-btn>

          <v-btn
            v-else
            :to="{ name: 'about' }"
            class="no-gradient-mask"
            color="primary"
            rounded
          >
            S'INSCRIRE
          </v-btn>
        </span>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import adfTuner from "../tuner/show.vue";
import adfSearch from "./search.vue";
import logo from "@assets/images/logo.svg";
import tuningFork from "@assets/images/tuning-fork.svg";
import tuningForkHover from "@assets/images/tuning-fork-hover.svg";

export default Vue.component("adf-app-bar", {
  components: {
    adfTuner,
    adfSearch
  },
  data() {
    return {
      searchBarIsFocused: false,
      search: null
    };
  },
  mounted() {
    console.log("heya !->", this.$el);
  },
  methods: {
    toggleDrawer() {
      this.$emit("toggleDrawer");
    },
    goToProfile() {
      this.$router.push({ name: "current_user_show" });
    }
  },
  computed: {
    logo() {
      return logo;
    },
    color() {
      switch (this.bottomNav) {
        case 0:
          return "blue-grey";
        case 1:
          return "teal";
        case 2:
          return "brown";
        case 3:
          return "indigo";
      }
    },
    tuningFork: () => tuningFork,
    tuningForkHover: () => tuningForkHover,
    ...mapState(["currentUser"]),
    ...mapGetters(["currentUserHasRoles"])
  }
});
</script>

<style lang="scss" scoped>
#main-bar {
  border-bottom: 1px solid #eee;
  border-color: #eee !important;
  z-index: 10;
  //padding-right: 15px !important;
  //padding-left: 15px !important;
  .bar-container {
    // max-width: 960px;
    .v-toolbar__title {
      display: flex;
      align-items: center;

      img {
        vertical-align: middle;
      }

      .logo {
        max-height: 43px;
      }

      // .adf-picto {
      //   max-height: 35px;
      //   border-right: 1px solid #f9320145;
      //   padding-right: 15px;
      // }
    }
  }

  .tuner-icon {
    transform: rotate(35deg);
  }

  .tuner-btn {
    height: 40px;
  }
}
</style>
