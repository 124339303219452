<template>
  <div>
    <v-card>
      <v-card-title>
        VOS LISTES
        <v-spacer />
        <adf-song-sets-form
          ref="songSetForm"
          @songSets:saved="fetchSongSets({ reset: true })"
        />
      </v-card-title>

      <span v-if="songSets.length > 0">
        <v-list-item-group v-model="selectedSongSet" id="song-sets">
          <v-list-item two-line v-for="(songSet, i) in songSets" :key="i">
            <v-list-item-content>
              <v-list-item-title>{{ songSet.title }}</v-list-item-title>
              <v-list-item-subtitle>{{
                songSet.dueTo | moment("DD/MM/YYYY")
              }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-icon class="">
              <adf-song-sets-destroy
                :song-set="songSet"
                @songSets:destroyed="fetchSongSets({ reset: true })"
              />
              <v-btn
                icon
                @click.stop="editSongSet(songSet)"
                class="song-sets-edit"
              >
                <v-icon>mdi-pencil-outline</v-icon>
              </v-btn>
            </v-list-item-icon>
          </v-list-item>
        </v-list-item-group>
        <v-list-item class="d-flex justify-center" v-if="totalCount > 4">
          <v-btn
            id="more-button"
            icon
            :loading="loading"
            @click="fetchSongSets"
          >
            <v-icon v-if="noMorePages" color="success">mdi-check-all</v-icon>
            <v-icon v-else>mdi-chevron-double-down</v-icon>
          </v-btn>
        </v-list-item>
      </span>
      <div v-else>
        <v-card-text class="text-center pt-0">
          Vous n'avez aucune liste de chants pour l'instant.

          <v-btn class="mt-2 mb-4" @click="addSongSet">
            CRÉEZ-EN UNE
          </v-btn>
        </v-card-text>
      </div>
    </v-card>
  </div>
</template>
<script>
import adfSongSetsForm from "./form.vue";
import adfSongSetsDestroy from "./destroy.vue";
import { decamelizeKeys, camelizeKeys } from "xcase";
export default {
  components: {
    adfSongSetsForm,
    adfSongSetsDestroy,
  },
  data() {
    return {
      songSets: [],
      loading: false,
      noMorePages: false,
      page: 0,
      selectedSongSet: null,
      totalCount: 0,
    };
  },
  mounted() {
    this.fetchSongSets();
  },
  watch: {
    selectedSongSet(selectedSongSetIndex) {
      let selectedSongSet = this.songSets[selectedSongSetIndex];
      this.$router.push({
        name: "song_sets_show",
        params: { song_set_id: selectedSongSet.id.toString() },
      });
    },
  },
  computed: {},
  methods: {
    addSongSet() {
      this.$refs.songSetForm.dialog = true;
    },
    editSongSet(songSet) {
      this.$refs.songSetForm.edit(songSet);
    },
    fetchSongSets(opts) {
      if (!opts) opts = {};

      if (opts.reset) {
        this.noMorePages = false;
        this.page = 0;
      }

      this.page++;
      if (!this.noMorePages && !this.isLoading) {
        this.isLoading = true;
        let url = `/api/listes-de-chants/page/${this.page}.json`;
        this.$http.get(url).then(
          (res) => {
            this.isLoading = false;
            let newItems = camelizeKeys(res.data.items);
            this.totalCount = res.data.count;
            if (this.page == 1) {
              this.songSets = newItems;
            } else {
              if (newItems.length == 0) {
                this.noMorePages = true;
              }
              this.songSets = this.songSets.concat(newItems);
            }
          },
          (error) => {
            console.warn("something went wrong");
          }
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.v-card__title {
  //position: sticky;
  //top: 140px;
  background: #fbfbfba6;
  z-index: 1;
}
</style>
