<template>
  <v-autocomplete
    v-model="selectedSearchItem"
    :search-input.sync="search"
    dense
    filled
    rounded
    @focus="searchBarIsFocused = true"
    @blur="searchBarIsFocused = false"
    :items="searchItems"
    item-text="title"
    item-value="id"
    return-object
    :class="
      `align-center ml-4 main-search-bar ${searchBarIsFocused ? 'wide' : ''} ${
        fullWidth ? 'full-width' : ''
      }`
    "
    hide-details
    no-filter
    auto-select-first
    prepend-inner-icon="mdi-magnify"
    :hide-no-data="!search"
    :no-data-text="`Aucun résultat ${search ? `pour '${search}'` : ''}`"
  >
    <template v-slot:item="data">
      <adf-songs-item
        @click.stop="goToSong(data.item)"
        :song="data.item"
        :max-width="400"
      ></adf-songs-item>
    </template>
  </v-autocomplete>
</template>
<script>
import Vue from "vue";
import debounce from "debounce";
import adfSongsItem from "../songs/partials/item.vue";
import { searchSongs } from "../../api/search";
import { camelizeKeys } from "xcase";

export default Vue.component("adf-app-bar-search", {
  props: {
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },
  components: { adfSongsItem },
  data: () => ({
    search: null,
    searchBarIsFocused: false,
    searchItems: [],
    selectedSearchItem: null,
  }),
  methods: {
    goToSong(song) {
      console.log("song is", song);
      this.$router
        .push({
          name: "songs_show",
          params: {
            song_id: song.id,
          },
        })
        .finally(() => {
          this.$nextTick(() => {
            this.searchItems = [];
            this.selectedSearchItem = null;
          });
        });
    },
  },
  watch: {
    search: debounce(function(s) {
      if (!!s && s.length)
        // searchSongs(s).then(({hits}) => {
        //   console.log("hits are", hits);
        //   this.searchItems = camelizeKeys(hits)
        // })
        this.$http.get(`/api/recherche/${s}`).then((r) => {
          console.log("r", r.data);
          this.searchItems = r.data.items;
        });
    }, 800),
    selectedSearchItem(searchItem) {
      if (!!searchItem) {
        this.goToSong(searchItem);
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.main-search-bar {
  max-width: 200px;
  $lasting: 0.5s;
  -webkit-transition: all $lasting ease;
  -moz-transition: all $lasting ease;
  -ms-transition: all $lasting ease;
  -o-transition: all $lasting ease;
  transition: all $lasting ease;
  &.wide {
    max-width: 400px;
  }
  &.full-width {
    min-width: 100%;
    max-width: 100%;
    padding-right: 35px;
  }
}
</style>
