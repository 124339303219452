import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";
import { setupMetas } from "./routes";
import { camelizeKeys } from "xcase";
import _ from "lodash/object";
Vue.use(Vuex);

const vuexPersist = new VuexPersist({
  key: "adf-app",
  storage: window.localStorage,
});

const store = new Vuex.Store({
  plugins: [vuexPersist.plugin],
  state: {
    currentUser: null,
    token: null,
    refreshToken: null,
    songSetsStorage: [],
    uiStates: {
      isReady: false,
      hideMenus: false,
      bodyStyles: {},
    },
    alerts: {
      info: null,
      error: null,
    },
    // Defaults are overriden inside app.js file
    uiOptions: {
      noTopMargin: false, // removes top margin between main container and top bar
      bodyStyles: {}, // apply specific body styles like background color or image
    },
  },
  actions: {},
  getters: {
    alert: (state) => (alertType) => state.alerts[alertType],
    currentUser: (state) => () => state.currentUser,
    token: (state) => () => state.token,
    refreshToken: (state) => () => state.refreshToken,
    findSongSetStorage: (state) => (songSetId) => {
      return state.songSetsStorage.find(
        (songSetStorage) => songSetStorage.id == songSetId
      );
    },
    getUiStates: (state) => (prop) => {
      if (state.uiStates.hasOwnProperty(prop)) {
        return state.uiStates[prop];
      }
    },
    getUiOptions: (state) => (prop) => {
      if (state.uiOptions.hasOwnProperty(prop)) {
        return state.uiOptions[prop];
      }
    },
    currentUserHasRoles: (state) => (role) => {
      // TODO : migrate this to organization role
      return true; //state.currentUser && state.currentUser.role == role
    },
  },
  mutations: {
    setAlerts(state, alerts) {
      state.alerts = {
        ...alerts,
      };
    },
    setSongSetsStorage(state, songSetStorage) {
      let existingItemIndex = state.songSetsStorage.findIndex(
        (sss) => sss.id == songSetStorage.id
      );
      if (existingItemIndex >= 0) {
        state.songSetsStorage[existingItemIndex] = songSetStorage;
      } else {
        state.songSetsStorage.push(songSetStorage);
      }
    },
    setToken(state, token) {
      state.token = token;
    },
    setRefreshToken(state, token) {
      state.refreshToken = token;
    },
    setCurrentUser(state, currentUser) {
      let userData = camelizeKeys(currentUser);
      // localStorage.setItem("current_user", escape(JSON.stringify(userData)))
      if (userData && Object.keys(userData).length > 0)
        state.currentUser = userData;
      else state.currentUser = null;
    },
    setUiStates(state, uiStates) {
      // state.uiStates = { ...state.uiStates, ...uiStates };
      state.uiStates = _.merge(state.uiStates, uiStates);
      // _.merge is not sufficent if you have
      // previousUiStates = {bodyStyles: {background: 'http://myImg.jpg}}
      // newUiStates = {bodyStyles: {}}
      // => final uiStates bodyStyles won't be removed 
      if (uiStates.bodyStyles)
        state.uiStates.bodyStyles = uiStates.bodyStyles
    },
    setUiOptions(state, uiOptions) {
      state.uiOptions = { ...state.uiOptions, ...uiOptions };
    },
    refreshRouteMetas(state, options) {
      if (!options) var options = { route, meta };
      options.route.meta.title = options.meta.title;
      options.route.meta.metaTags = options.meta.metaTags;
      setupMetas(options.route);
    },
  },
});

export default store;
