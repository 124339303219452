<template>
  <v-container fluid fill-height>
    <v-flex xs12 sm8 offset-sm2 md6 offset-md3 class="text-center">
      <router-link to="/a-propos">
        <img
          class="my-5 animated fadeInDown adf-logo"
          src="@assets/images/logo-big.svg"
          v-if="true"
        />
      </router-link>
      <v-form ref="form" v-on:submit.prevent="goNext" :disabled="loading">
        <v-card class="mx-auto" max-width="500" :loading="loading">
          <v-card-title class="title font-weight-regular justify-space-between">
            <span>{{ currentTitle }}</span>
            <!-- <v-avatar
              color="primary lighten-2"
              class="subheading white--text"
              size="24"
              v-text="step"
            ></v-avatar> -->
          </v-card-title>

          <v-window v-model="step">
            <v-window-item :value="1">
              <v-card-text>
                <adf-alerts></adf-alerts>
                <v-alert
                  dismissible
                  type="success"
                  v-if="this.$route.params.info"
                >
                  {{ this.$route.params.info }}
                </v-alert>
                <v-alert
                  dismissible
                  type="warning"
                  v-if="this.$route.params.warning"
                >
                  {{ this.$route.params.warning }}
                </v-alert>
                <v-alert
                  dismissible
                  icon="mdi-arrow-down-thick"
                  type="info"
                  v-if="showPasswordInstructions"
                >
                  Ok ! Commencez par renseigner votre adresse email
                </v-alert>
                <v-text-field
                  label="Email"
                  v-model="user.email"
                  placeholder="email@gmail.com"
                  :autofocus="true"
                  :rules="formRules.email"
                  ref="emailInput"
                ></v-text-field>
                <v-alert type="error" v-if="error">
                  {{ error }}
                </v-alert>
              </v-card-text>
            </v-window-item>

            <v-window-item :value="2">
              <v-card-text>
                <v-text-field
                  v-if="step == 2"
                  label="Mot de passe"
                  type="password"
                  v-model="user.password"
                  :autofocus="true"
                  ref="passwordInput"
                  :rules="formRules.password"
                ></v-text-field>
                <span class="caption grey--text text--darken-1">
                  Veuillez saisir un mot de passe.
                </span>
              </v-card-text>
            </v-window-item>

            <v-window-item :value="3">
              <div class="pa-4 text-center">
                <adf-loader position="relative"></adf-loader>
                <!-- <span class="caption grey--text">Thanks for signing up!</span> -->
              </div>
            </v-window-item>

            <v-window-item :value="4">
              <div class="pa-4 text-center">
                <h3 class="title font-weight-light mb-2">Ok !</h3>
                <v-alert type="success">
                  Votre demande a été prise en compte ! Vous allez recevoir un
                  email pour changer votre mot de passe.
                  <br />
                  Cliquez sur le lien pour pouvoir le modifier.
                  <br />
                </v-alert>
              </div>
            </v-window-item>
          </v-window>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn
              :disabled="step === 1 || step === 3 || step == 4"
              text
              @click="goBack()"
            >
              Retour
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" depressed class="next-button" type="submit">
              Suivant
            </v-btn>
          </v-card-actions>
        </v-card>
        <p class="mb-2 text-center white--text">
          Vous n'avez pas encore de compte ?
          <router-link :to="{ name: 'signup' }">Créez-en un !</router-link>
        </p>
        <p class="mt-2 text-center white--text">
          Vous avez oublié votre mot de passe ?
          <a class="underlined" @click="togglePasswordInstructions"
            >Recréez-en un !</a
          >
        </p>
      </v-form>
    </v-flex>
  </v-container>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import { EventBus } from "../event-bus.js";
import { login } from "../auth.js";
import adfAlerts from "./alerts/alerts.vue";
import AboutImg from "@assets/images/about.jpg";
export default Vue.component("adf-login", {
  components: { adfAlerts },
  data: () => ({
    step: 1,
    error: null,
    loading: false,
    showPasswordInstructions: false,
    user: {
      email: null,
      password: null,
    },
    formRules: {
      email: [
        (v) => !!v || "Veuillez renseigner une adresse email",
        (v) =>
          (v && v.isEmail()) || "Veuillez renseigner une adresse email valide",
      ],
      password: [
        (v) => !!v || "Veuillez choisir un mot de passe",
        (v) =>
          (v && v.length >= 8) ||
          "Veuillez choisir un mot de passe d'au moins 8 caractères",
      ],
    },
  }),
  created() {},
  methods: {
    togglePasswordInstructions() {
      this.showPasswordInstructions = !this.showPasswordInstructions;
      this.step = 1;
      this.$refs.emailInput.focus();
    },
    validEmail(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    goBack() {
      this.step--;
    },
    goNext() {
      console.log("going next");

      if (this.loading) {
        console.warn("form already loading ...");
        return;
      }

      if (!this.$refs.form.validate()) {
        console.warn("form validation failed !", this.$refs.form);
        return;
      }

      // Deal with password instructions if active
      if (this.showPasswordInstructions) {
        const passwordUrl = `/utilisateurs/mot-de-passe.json`;
        this.loading = true;
        this.$http
          .post(passwordUrl, {
            user: {
              email: this.user.email,
            },
          })
          .then(
            (res) => {
              this.loading = false;
              this.step = 4;
            },
            () => {
              this.loading = false;
            }
          );
        return;
      }

      // if (!this.isNextButtonDisabled) {
      // }

      this.step++;

      if (this.step == 3) {
        console.log("logging in");
        this.loading = true;
        login(this.user.email, this.user.password).then(
          (user) => {
            console.log("user is", user);
            this.$store.commit("setCurrentUser", user);
            this.$store.commit("setUiStates", {
              isReady: true,
              hideMenus: false,
            });

            this.loading = false;
            this.$router.push({
              name: "dashboard",
            });
          },
          (error) => {
            console.log("oups something went wrong", error);
            this.step = 1;
            this.loading = false;
            if (error.response.status == 400) {
              this.error =
                "Adresse email ou mot de passe incorrect - veuillez réessayer.";
            } else {
              this.error = "Une erreur est survenue - veuillez réessayer.";
            }
            this.user.password = null;
          }
        );
        // this.$auth.login({
        //   body: {username: this.user.email, password: this.user.password},
        //   staySignedIn: true,
        //   // redirect: {
        //   //   name: "dashboard"
        //   // },
        //   remember: JSON.stringify({username: this.user.email})
        // }).then((r) => {
        //   console.log("r", r)
        //   this.$auth.fetch().then((r) => {
        //     this.$store.commit("setCurrentUser", r.body)
        //     this.$store.commit("setUiStates", {
        //       isReady: true,
        //       hideMenus: false
        //     });
        //   })
        // }, (error) => {
        //   this.step = 1
        //   if (error.response.status == 400) {
        //     this.error = "Adresse email ou mot de passe incorrect - veuillez réessayer."
        //   }
        //   else {
        //     this.error = "Une erreur est survenue - veuillez réessayer."
        //   }
        //   this.user.password = null
        // })
      }
    },
  },
  mounted() {
    this.$store.commit("setUiStates", {
      bodyStyles: {
        background: `url(${AboutImg})`,
        "background-size": "cover",
        "background-position": "bottom",
      },
      hideMenus: true,
    });
    this.$nextTick(() => {
      EventBus.$emit("main-wrapper:toggle-blur", true);
    });
  },
  beforeRouteEnter(to, from, next) {
    console.log("----");
    next((vm) => {
      console.log("hello");
      vm.$store.commit("setUiStates", {
        bodyStyles: {
          background: `url(${AboutImg})`,
          "background-size": "cover",
          "background-position": "bottom",
        },
        hideMenus: true,
      });
    });
  },
  beforeDestroy() {
    EventBus.$emit("main-wrapper:toggle-blur", false);
    this.$store.commit("setUiStates", { bodyStyles: {} });
  },
  computed: {
    currentTitle() {
      switch (this.step) {
        case 1:
          return "Connexion";
        case 2:
          return "Mot de passe";
        default:
          return "";
      }
    },
    isNextButtonDisabled() {
      var isDisabled = true;
      switch (this.step) {
        case 1:
          if (this.user.email && this.validEmail(this.user.email)) {
            isDisabled = false;
          }
          break;
        case 2:
          if (this.user.password && this.user.password.length > 0) {
            isDisabled = false;
          }
          break;
        default:
          isDisabled = true;
      }
      return isDisabled;
    },
  },
});
</script>

<style scoped>
p {
  font-size: 14px;
  color: #333;
  margin-top: 10px;
}
.adf-logo {
  width: 100%;
  max-width: 220px;
}
</style>
