import Vue from "vue"

Vue.filter('truncate', function (string, n, useWordBoundary) {
    if (!string) return ''
    string = string.toString()
    if (string.length <= n) { return string; }
    const subString = string.substr(0, n-1); // the original check
    return (useWordBoundary
        ? subString.substr(0, subString.lastIndexOf(" "))
        : subString) + " ...";
})