<template>
  <div>
    <v-row>
      <v-col cols="12" sm="4" class="text-center">
        <v-avatar size="200" class="avatar-wrapper">
          <v-btn
            depressed
            v-if="currentUser.id == user.id"
            @click="avatarDialog = true"
            dark
            fab
            color="primary"
            class="change-avatar"
          >
            <v-icon>mdi-camera-plus</v-icon>
          </v-btn>
          <v-img
            max-width="200"
            max-height="200"
            :src="currentUser.avatar.original"
            :alt="`Avatar de ${currentUser.fullName}`"
            class="user-avatar"
          />
        </v-avatar>
      </v-col>
      <v-col cols="12" sm="8" class="text-center text-sm-left">
        <h1 class="text-lighter">{{ currentUser.fullName }}</h1>
        <v-btn class="mt-3" @click="signOut()">
          SE DÉCONNECTER
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog v-model="avatarDialog" width="500">
      <v-card class="mx-auto">
        <v-card-title class="title font-weight-regular justify-space-between">
          <span>Changer d'avatar</span>
        </v-card-title>
        <v-card-text>
          <adf-users-avatar-changer
            ref="avatarChanger"
            @onUserAvatarSaved="avatarDialog = false"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { accessTokensRevoke } from "../../api/access-tokens";
export default {
  name: "adf-users-show",
  props: {
    userId: {
      type: String,
    },
  },
  data() {
    return {
      user: {},
      avatarDialog: false,
    };
  },
  computed: {
    ...mapState({
      currentUser: "currentUser",
    }),
  },
  mounted() {
    //do something after mounting vue instance
    if (this.userId) {
      // fetch user
    } else {
      this.user = this.currentUser;
    }
  },
  methods: {
    signOut() {
      accessTokensRevoke().then(() => {
        this.$store.commit("setCurrentUser", null);
        this.$store.commit("setUiStates", { hideMenus: false });
        this.$router.push({ name: "about" });
      });
      // this.$auth.logout()
      // .then((r) => {
      //   this.$store.commit("setCurrentUser", null);
      //   this.$store.commit("setUiStates", {hideMenus: false})
      //   this.$router.push({name: "about"})
      // })
    },
  },
};
</script>
<style lang="scss" scoped>
.user-avatar {
  border: 4px solid #490a00;
  max-width: 100%;
  height: auto;
}
.avatar-wrapper {
  max-width: 100%;
}
.change-avatar {
  position: absolute;
  z-index: 1;
}
</style>
