import Vue from "vue";
import { camelizeKeys, decamelizeKeys } from "xcase";
import { adfHttp } from "../plugins/vue_axios";
import store from "../store";

const oauthClientId = "FTBoC8YPM-EDAmbFXF5mgTVJt4ZaFotaZg24UScPjv4";
const oauthSecret = "m05ad4pF61xib45LfsVejtfDffXKgzHDdAeSwtWttGw";

export const accessTokensCreate = async (
	params = {},
	grantType = "password"
) => {
	const url = `/oauth/token.json`;
	console.log("oooooo");

	const response = await adfHttp().post(
		url,
		decamelizeKeys({
			...params,
			clientId: oauthClientId,
			clientSecret: oauthSecret,
			grantType,
		})
	);

	const { accessToken, refreshToken } = response.data;
	store.commit("setRefreshToken", refreshToken);
	store.commit("setToken", accessToken);
	localStorage.setItem('currentAccessToken', accessToken)
	localStorage.setItem('currentRefreshToken', refreshToken)
	return accessToken;
};

export const accessTokensRevoke = async () => {
	const url = `/oauth/revoke.json`;
	const token = store.getters.token();

	try {
		const response = await adfHttp().post(
			url,
			decamelizeKeys({
				token,
			})
		);

		store.replaceState({});

		return true;
	} catch (e) {
		console.warn(e);
	}
};
