<template>
  <v-btn
    block
    rounded
    :class="`favorite-key-btn text-center ${isActive ? 'yellow darken-1' : ''}`"
    @click.stop="toggle"
  >
    CLÉ PRÉFÉRÉE
    <v-icon :color="`${isActive ? 'white' : 'yellow darken-1'}`">{{
      icon
    }}</v-icon>
  </v-btn>
</template>
<script>
import Vue from "vue";
import { decamelizeKeys, camelizeKeys } from "xcase";
import {mapState} from "vuex";
export default Vue.component("adf-user-song-preference", {
  props: {
    song: {
      type: [Object],
      required: true
    },
    selectedKey: {
      type: String,
      required: true
    },
    selectedCapo: {
      type: Number,
      required: true
    }
  },
  created() {
    if (this.selectedKey)
      this.userSongPreference.favoriteKey = this.selectedKey;
    if (this.selectedCapo)
      this.userSongPreference.favoriteCapo = this.selectedCapo;
    this.isActive = this.song.favoriteKey == this.selectedKey;
  },
  data() {
    return {
      isActive: false,
      userSongPreference: {}
    };
  },
  mounted() {},
  methods: {
    toggle() {
      if (!this.currentUser) {
        this.$router.push({name: "about"})
        return;
      }

      this.isActive = !this.isActive;
      if (this.isActive) {
        this.save();
      } else {
        this.destroy();
      }
    },
    destroy() {
      let url = `/api/chants/${this.song.id}/favoris.json`;
      this.$http.delete(url, decamelizeKeys({})).then(response => {
        // let usp = camelizeKeys(response.body)
        // this.$emit("usp:update", usp)
      });
    },
    save() {
      let url = `/api/chants/${this.song.id}/favoris.json`;
      this.$http
        .put(
          url,
          decamelizeKeys({
            userSongPreference: this.userSongPreference
          })
        )
        .then(response => {
          let usp = camelizeKeys(response.data);
          this.$emit("usp:update", usp);
        });
    }
  },
  watch: {
    selectedKey(newKey) {
      this.isActive = this.song.favoriteKey == newKey;
      this.userSongPreference.favoriteKey = newKey;
    },
    selectedCapo(newCapo) {
      this.userSongPreference.favoriteCapo = newCapo;
      if (this.isActive) this.save();
    }
  },
  computed: {
    icon() {
      return `mdi-star${!this.isActive ? "-outline" : ""}`;
    },
    ...mapState(['currentUser'])
  }
});
</script>
<style lang="scss" scoped></style>
