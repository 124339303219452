import main_feed from "./components/main_feed.vue";
import dashboard from "./components/dashboard/show.vue";
import songsShow from "./components/songs/show.vue";
import songsExporter from "./components/songs/exporter.vue";
import songsForm from "./components/songs/form.vue";
import login from "./components/login.vue";
import about from "./components/about.vue";
import usersLostPassword from "./components/users/lost_password.vue";
import signup from "./components/signup.vue";
import welcome from "./components/users/welcome.vue";
import usersShow from "./components/users/show.vue";
import songSetsShow from "./components/song_sets/show.vue";
import store from "./store.js";

let mainAppTitle = "ADF | ";

const checkAlertMessageAndRedirect = async (to, from, next) => {
  // console.log("to,fro", to.params);
  const { info, error } = to.params;
  store.commit("setAlerts", { info, error });
  const currentUser = await store.getters.currentUser();
  if (!currentUser) {
    next({
      name: "login",
    });
  } else {
    next({
      name: "dashboard",
    });
  }
};

export default [
  {
    path: "/error/:error",
    component: usersShow,
    name: "users_show",
    meta: { auth: false },
    beforeEnter: checkAlertMessageAndRedirect,
  },
  {
    path: "/info/:info",
    component: usersShow,
    name: "users_show",
    meta: { auth: false },
    beforeEnter: checkAlertMessageAndRedirect,
  },
  {
    path: "/warning/:warning",
    component: usersShow,
    name: "users_show",
    meta: { auth: false },
    beforeEnter: checkAlertMessageAndRedirect,
  },
  // {path:'/', redirect: {name: "main_feed", meta: {auth: true}}},
  {
    path: "/",
    redirect: { name: "login" },
  },
  {
    path: "/compte",
    component: usersShow,
    name: "current_user_show",
    meta: { auth: true },
  },
  {
    path: "/profil/:id",
    component: usersShow,
    name: "users_show",
    meta: { auth: true },
    props(route) {
      return {
        userId: route.params.id,
      };
    },
  },
  {
    path: "/tableau-de-bord",
    component: dashboard,
    name: "dashboard",
    meta: {
      auth: true,
      uiOptions: {
        noTopMargin: false,
      },
    },
  },
  {
    path: "/chants",
    component: dashboard,
    name: "songs_index",
    props: () => ({ routeDashboardPane: 2 }),
    meta: {
      auth: true,
      uiOptions: {
        noTopMargin: false,
      },
    },
  },
  {
    path: "/communaute",
    component: dashboard,
    name: "community",
    props: () => ({ routeDashboardPane: 3 }),
    meta: {
      auth: true,
      uiOptions: {
        noTopMargin: false,
      },
    },
  },
  {
    path: "/chants/nouveau",
    component: songsForm,
    name: "songs_new",
    meta: { auth: true },
  },
  {
    path: "/listes-de-chants/:song_set_id",
    component: songSetsShow,
    name: "song_sets_show",
    meta: { auth: false },
    props(route) {
      return {
        songSetId: route.params.song_set_id,
      };
    },
  },
  {
    path: "/chants/:song_id/modifier",
    component: songsForm,
    name: "songs_edit",
    meta: { auth: true },
    props(route) {
      return {
        songId: route.params.song_id,
      };
    },
  },
  {
    path: "/chants/:song_id",
    component: songsShow,
    name: "songs_show",
    meta: {
      // auth: true,
      title: "",
      metaTags: [
        {
          name: "description",
          content: "The about page of our example app.",
        },
        {
          property: "og:description",
          content: "The about page of our example app.",
        },
      ],
    },
    props(route) {
      return {
        songId: route.params.song_id,
      };
    },
  },
  {
    path: "/chants/:song_id/exporter",
    component: songsExporter,
    name: "songs_export",
    meta: {
      // auth: true,
      title: "",
      metaTags: [
        {
          name: "description",
          content: "The about page of our example app.",
        },
        {
          property: "og:description",
          content: "The about page of our example app.",
        },
      ],
    },
    props(route) {
      return {
        songId: route.params.song_id,
      };
    },
  },
  // {path:'/bienvenue', component: welcome, name: "welcome", meta: {auth: true}},
  {
    path: "/communaute",
    component: main_feed,
    name: "main_feed",
    meta: { auth: true },
  },
  {
    path: "/se-connecter/:info?/:warning?",
    name: "login",
    components: {
      fullWidth: login,
    },
    meta: {
      auth: false,
    },
    beforeEnter: async (to, from, next) => {
      const currentUser = await store.getters.currentUser();
      if (!currentUser) {
        next();
      } else {
        next({
          name: "dashboard",
        });
      }
    },
  },
  {
    path: "/inscription",
    name: "signup",
    components: {
      fullWidth: signup,
    },
    meta: { auth: false },
  },
  {
    path: "/a-propos",
    name: "about",
    components: {
      fullWidth: about,
    },
    meta: { auth: false },
  },

  {
    path: "/utilisateurs/mot-de-passe-oublie/:token",
    name: "lost_password",
    component: usersLostPassword,
    props: (route) => ({ passwordToken: route.params.token }),
    meta: { auth: false },
  },
  // {path:'/insideSystem',component:insideSystem},
  // {path:'/forgotPassword',component:forgotPassword}
];

export var setupMetas = function(to, next) {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);
  // const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle)
    document.title = `${mainAppTitle}${nearestWithTitle.meta.title}`;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(
    document.querySelectorAll("[data-vue-router-controlled]")
  ).map((el) => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) {
    if (!next) return;
    return next();
  }

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));
};

export const setupRouting = function(router) {
  // This callback runs before every route change, including on page load.
  router.beforeEach(async (to, from, next) => {
    console.log("to is", to);
    if (to.meta.auth) {
      const currentUser = await store.getters.currentUser();
      if (!currentUser) {
        store.commit("setAlerts", {
          warning: "Vous devez être connecté pour continuer",
        });
        next({
          name: "login",
          // params: { warning: "Vous devez être connecté pour continuer" },
        });
        return;
      }
    }
    setupMetas(to);
    next();
  });
};
