import '@mdi/font/css/materialdesignicons.css'
// import 'vuetify/dist/vuetify.min.css'
import Vue from 'vue'
import Vuetify from 'vuetify'

Vue.use(Vuetify)
export default new Vuetify({
  customProperties: true,
  theme: {
    themes: {
      light: {
        primary: '#FE490D',
        secondary: '#FFDE49',
        accent: '#ff3b00',
        error: '#b71c1c',
        lightGray: '#eeeeee'
      },
    },
  },
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
})
