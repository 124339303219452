<template>
  <v-card class="d-sm-block d-none">
    <div class="pt-4 px-4">
      <span>
        <v-combobox
          id="key-selector"
          v-model="internalSelectedKey"
          :items="keys"
          label="Clé"
          :disabled="keyIsLocked"
          outlined
          class="mb-4 no-messages"
        >
          <template slot="item" slot-scope="data">
            {{ data.item }}
            <v-icon
              color="yellow darken-1"
              class="ml-auto"
              v-if="data.item == fetchedSong.favoriteKey"
            >
              mdi-star
            </v-icon>
          </template>
        </v-combobox>
        <v-select
          class="no-messages mb-0"
          v-model="internalSelectedCapo"
          :items="capos"
          label="Capo"
          outlined
        ></v-select>
      </span>
    </div>
    <v-card-actions class="pa-4" v-if="selectedKey">
      <adf-user-song-preference
        :song="fetchedSong"
        :selected-key="selectedKey"
        :selected-capo="selectedCapo"
        @usp:update="internalUpdateSongPreferences"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
import {mapState} from "vuex"
export default {
  name: "adf-songs-key-selector", 
  props: {
    selectedKey: {
      type: String,
      required: true,
    },
    selectedCapo: {
      type: Number,
      required: true,
    },
    fetchedSong: {
      type: Object,
      required: true,
    },
    updateSongPreferences: {
      type: Function,
      required: true,
    },
    keyIsLocked: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.internalSelectedKey = this.selectedKey
    this.internalSelectedCapo = this.selectedCapo
  },
  methods: {
    internalUpdateSongPreferences(usp) {
      this.updateSongPreferences(usp);
      // Fake changing keys to update selectedKeys favorite icon
      this.$set(this.keys, 0, "C");
    }
  },
  computed: {
    ...mapState({
      currentUser: "currentUser",
      // 'isReady': (uiStates) => {
      //   console.log("uuu", uiStates.isReady)
      //   return uiStates.isReady
      // }
    }),
  },
  data() {
    return {
      internalSelectedKey: null,
      internalSelectedCapo: null,
      keys: [
        "C",
        "C#",
        "D",
        "D#",
        "E",
        "F",
        "F#",
        "G",
        "G#",
        "A",
        "A#",
        "B",
      ],
      capos: Array.from(Array(13), (x, i) => i),
    };
  },
  watch: {
    selectedKey(newKey) {
      this.internalSelectedKey = newKey
    },
    selectedCapo(newCapo) {
      this.internalSelectedCapo = newCapo
    },
    internalSelectedKey(newKey) {
      this.$emit("update:selectedKey", newKey)
    },
    internalSelectedCapo(newCapo) {
      this.$emit("update:selectedCapo", newCapo)
    }
  }
};
</script>

<style></style>
