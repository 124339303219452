<template>
  <v-icon small v-if="!hidden" @click="destroy(songSetPreference)">
    mdi-minus-circle-outline
  </v-icon>
</template>
<script>
export default {
  name: "adf-song-set-preferences-destroy",
  props: {
    songSetPreference: {
      type: Object,
    },
    hidden: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    destroy(songSetPreference) {
      console.log("will destroy", songSetPreference);
      if (songSetPreference) {
        let url = `/api/listes-de-chants/${this.songSetId}/preferences-de-chants/${songSetPreference.id}.json`;
        this.$http.delete(url).then((res) => {
          this.$emit("destroyed", songSetPreference);
        });
      }
    },
  },
};
</script>
