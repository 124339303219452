import Vue from "vue";
import { camelizeKeys, decamelizeKeys } from "xcase";
import { accessTokensCreate } from "./api/access-tokens";
import { usersShow } from "./api/users";

export const login = async (username, password) => {
  const accessToken = await accessTokensCreate({ username, password });
  console.log("accessToken is", accessToken);

  const user = await usersShow();
  return user; 
};

export const signup = async (user) => {
  const url = `/api/utilisateurs.json`;
  try {
    const response = await Vue.$http.post(url, {
      user: decamelizeKeys(user)
    })
    console.log("response is", response);
    const createdUser = camelizeKeys(response.data);
    return Promise.resolve(createdUser)
  }
  catch(e) {
    console.warn("Could not signup", e);
    return Promise.reject(e)
  }
}
