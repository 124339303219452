<template>
  <v-container fluid fill-height>
    <v-flex xs12 sm8 offset-sm2 md6 offset-md3 class="text-center">
      <router-link to="/a-propos">
        <img
          class="my-5 animated fadeInDown"
          src="@assets/images/logo-white.svg"
        />
      </router-link>
      <v-form
        ref="form"
        lazy-validation
        :disabled="isLoading"
        v-on:submit.prevent="goNext"
      >
        <v-card class="mx-auto" max-width="500">
          <v-card-title class="title font-weight-regular justify-space-between">
            <span>{{ currentTitle }}</span>
            <!-- <v-avatar
              color="primary lighten-2"
              class="subheading white--text"
              size="24"
              v-text="step"
            ></v-avatar> -->
          </v-card-title>

          <v-window v-model="step">
            <v-window-item :value="1">
              <v-card-text>
                <v-text-field
                  v-cloak
                  :autofocus="'autofocus'"
                  label="Email"
                  required
                  :rules="formRules.email"
                  v-model="user.email"
                  placeholder="email@gmail.com"
                  v-on:keyup.enter="goNext()"
                ></v-text-field>
                <v-alert type="error" v-if="error">
                  {{ error }}
                </v-alert>
                <span class="caption grey--text text--darken-1">
                  Cet email vous sera utile pour vous connecter.
                </span>
              </v-card-text>
            </v-window-item>

            <v-window-item :value="2">
              <v-card-text>
                <v-text-field
                  label="Mot de passe"
                  type="password"
                  required
                  :rules="formRules.password"
                  :autofocus="'autofocus'"
                  v-model="user.password"
                ></v-text-field>
                <v-text-field
                  label="Confirmation du mot de passe"
                  type="password"
                  required
                  :rules="formRules.passwordConfirm"
                  v-model="user.confirmedPassword"
                  v-on:keyup.enter="goNext()"
                ></v-text-field>
                <span class="caption grey--text text--darken-1">
                  Veuillez choisir un mot de passe.
                </span>
              </v-card-text>
            </v-window-item>

            <v-window-item :value="3">
              <v-card-text>
                <v-text-field
                  label="Nom"
                  type="text"
                  :autofocus="'autofocus'"
                  v-model="user.lastName"
                  :rules="formRules.lastName"
                  required
                ></v-text-field>
                <v-text-field
                  label="Prénom"
                  type="text"
                  required
                  :rules="formRules.firstName"
                  v-model="user.firstName"
                  v-on:keyup.enter="goNext()"
                ></v-text-field>
                <span class="caption grey--text text--darken-1">
                  Veuillez renseigner votre nom et prénom.
                </span>
              </v-card-text>
            </v-window-item>

            <!-- <v-window-item :value="4">
              <v-card-text>
                <span class="caption grey--text text--darken-1">
                  Afin de commencer à utiliser ADF, veuillez renseigner le nom
                  de votre groupe de musique ou de votre organisation :
                </span>
                <v-text-field
                  label="Nom du groupe"
                  type="text"
                  :autofocus="'autofocus'"
                  v-model="organization.name"
                  v-on:keyup.enter="goNext()"
                ></v-text-field>
              </v-card-text>
            </v-window-item> -->

            <v-window-item :value="4">
              <div class="pa-4 text-center">
                <h3 class="title font-weight-light mb-2">Bienvenue !</h3>
                <v-alert type="success">
                  Votre inscription a bien été validée ! Vous allez recevoir un
                  email pour confirmer votre compte.
                  <br />
                  N'oubliez pas de cliquer sur le lien contenu dans ce mail pour
                  finaliser votre inscription.
                  <br />
                </v-alert>
              </div>
            </v-window-item>
          </v-window>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn :disabled="step === 1 || step === 3" text @click="goBack()">
              Retour
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              :loading="isLoading"
              color="primary"
              class="next-button"
              depressed
              @click="goNext()"
            >
              Suivant
            </v-btn>
          </v-card-actions>
        </v-card>
        <p class="text-center white--text">
          Vous avez déjà un compte ?
          <router-link :to="{ name: 'login' }">Connectez-vous !</router-link>
        </p>
      </v-form>
    </v-flex>
  </v-container>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import { decamelizeKeys } from "xcase";
import { accessTokensCreate } from "../api/access-tokens.js";
import { login, signup } from "../auth.js";
import { EventBus } from "../event-bus.js";
import AboutImg from "@assets/images/about.jpg";
export default Vue.component("adf-login", {
  data() {
    return {
      step: 1,
      error: null,
      isLoading: false,
      emailHasBeenValidatedByRemote: false,
      user: {
        email: "",
        password: null,
        confirmedPassword: null,
      },
      organization: {
        name: "",
      },
      formRules: {
        email: [
          (v) => !!v || "Veuillez renseigner une adresse email",
          (v) =>
            (v && v.isEmail()) ||
            "Veuillez renseigner une adresse email valide",
        ],
        password: [
          (v) => !!v || "Veuillez choisir un mot de passe",
          (v) =>
            (v && v.length >= 8) ||
            "Veuillez choisir un mot de passe d'au moins 8 caractères",
        ],
        passwordConfirm: [
          (v) => !!v || "Veuillez confirmer votre mot de passe",
          (v) =>
            (v && v === this.user.password) ||
            "Les deux mots de passe ne correspondent pas",
        ],
        firstName: [(v) => !!v || "Veuillez renseigner votre prénom"],
        lastName: [(v) => !!v || "Veuillez renseigner votre nom"],
      },
    };
  },
  mounted() {
    this.$store.commit("setUiStates", {
      bodyStyles: {
        background: `url(${AboutImg})`,
        "background-size": "cover",
        "background-position": "bottom",
      },
    });
    this.$nextTick(() => {
      EventBus.$emit("main-wrapper:toggle-blur", true);
    });
  },
  beforeDestroy() {
    EventBus.$emit("main-wrapper:toggle-blur", false);
    this.$store.commit("setUiStates", { bodyStyles: {} });
  },
  methods: {
    availableEmail(email) {
      return this.$http.post("/api/utilisateurs/par-email.json", {
        email: email,
      });
    },
    validEmail: function(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    goBack: function() {
      this.step--;
      this.autoFocus();
    },
    goNext: function() {
      if (!this.$refs.form.validate()) return;

      switch (this.step) {
        case 3:
          this.isLoading = true;
          signup(this.user).then(
            (createdUser) => {
              this.user = {
                ...this.user,
                ...createdUser,
              };
              this.isLoading = false;
              this.step++;
              // login(this.user.email, this.user.password)
              //   .then(accessToken => {
              //     this.isLoading = false
              //     this.step ++
              //   })
            },
            (error) => {
              this.step = 1;
              if (error.response.status == 400) {
                this.error =
                  "Adresse email ou mot de passe incorrect - veuillez réessayer.";
              } else {
                this.error = "Une erreur est survenue - veuillez réessayer.";
              }
            }
          );
          // this.$auth.register({
          //   body: {
          //     user: {
          //       email: this.user.email,
          //       password: this.user.password,
          //       first_name: this.user.firstName,
          //       last_name: this.user.lastName
          //     }
          //   }
          // }).then((response) => {
          //   this.user = {
          //     ...this.user,
          //     ...response.data
          //   }
          //   this.isLoading = false
          //   this.step ++
          // }, (error) => {
          //   this.step = 1
          //   if (error.response.status == 400) {
          //     this.error = "Adresse email ou mot de passe incorrect - veuillez réessayer."
          //   }
          //   else {
          //     this.error = "Une erreur est survenue - veuillez réessayer."
          //   }
          // })

          break;
        case 4:
          console.log("login !");
          this.isLoading = true;
          login(this.user.email, this.user.password).then(
            (user) => {
              console.log("user is", user);
              this.isLoading = false;
              this.$store.commit("setCurrentUser", user);
              this.$store.commit("setUiStates", {
                isReady: true,
                hideMenus: false,
              });
              this.$router.push({
                name: "dashboard",
              });
            },
            (error) => {
              this.isLoading = false;
              console.log("oups something went wrong", error);
              this.step = 1;
              if (error.response.status == 400) {
                this.error =
                  "Adresse email ou mot de passe incorrect - veuillez réessayer.";
              } else {
                this.error = "Une erreur est survenue - veuillez réessayer.";
              }
              this.user.password = null;
            }
          );
          // let url = `/api/groupes.json`
          // this.$http.post(url, {
          //   organization: decamelizeKeys({
          //     owner_id: this.user.id,
          //     ...this.organization
          //   })
          // })
          // .then(() => {
          //   this.isLoading = false
          //   // this.step ++

          // })
          break;
        default:
          if (!this.isNextButtonDisabled) {
            this.step++;
          }
      }
    },
  },
  watch: {
    "user.email": {
      handler(email, emailWas) {
        if (email && email != emailWas) {
          this.emailHasBeenValidatedByRemote = false;
          this.error = "";
        }
        if (
          email &&
          this.validEmail(email) &&
          !this.emailHasBeenValidatedByRemote
        ) {
          this.availableEmail(email)
            .then((response) => {
              if (response.data.id) {
                this.error = "Cette adresse email est déjà utilisé.";
                this.emailHasBeenValidatedByRemote = false;
              }
            })
            .catch((error) => {
              if (error.response.status == 404) {
                this.emailHasBeenValidatedByRemote = true;
                this.error = "";
              }
            });
        }
      },
      deep: true,
    },
  },
  computed: {
    currentTitle() {
      switch (this.step) {
        case 1:
          return "S'inscrire";
        case 2:
          return "Choisir un mot de passe";
        case 3:
          return "Votre identité";
        default:
          return "";
      }
    },
    isNextButtonDisabled() {
      var isDisabled = true;
      switch (this.step) {
        case 1:
          if (
            this.user.email &&
            this.validEmail(this.user.email) &&
            this.emailHasBeenValidatedByRemote
          ) {
            isDisabled = false;
          }
          break;
        case 2:
          let isPasswordPresent =
            this.user.password && this.user.password.length > 0;
          let isConfirmedPasswordPresent =
            this.user.confirmedPassword &&
            this.user.confirmedPassword.length > 0;
          if (
            isPasswordPresent &&
            isConfirmedPasswordPresent &&
            this.user.password === this.user.confirmedPassword
          ) {
            isDisabled = false;
          }
          break;
        case 3:
          let isFirstNamePresent =
            this.user.firstName && this.user.firstName.length > 0;
          let isLastNamePresent =
            this.user.lastName && this.user.lastName.length > 0;
          if (isFirstNamePresent && isLastNamePresent) {
            isDisabled = false;
          }
          break;
        case 4:
          isDisabled = !(
            this.organization.name && this.organization.name.length > 0
          );
          break;
        default:
          isDisabled = true;
      }
      return isDisabled;
    },
  },
});
</script>

<style scoped>
p {
  font-size: 14px;
  color: #333;
  margin-top: 10px;
}
</style>
