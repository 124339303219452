<template>
  <div v-if="isLoading">
    <adf-loader></adf-loader>
  </div>
  <div v-else>
    <v-form ref="form" :value="formIsValid">
      <v-row>
        <v-col cols="8">
          <v-text-field
            v-model="fetchedSong.title"
            :disabled="isSaving"
            :rules="[rules.required]"
            label="Titre"
            outlined
            placeholder="Titre"
          ></v-text-field>

          <v-textarea
            v-model="fetchedSong.content"
            :disabled="isSaving"
            :rules="[rules.required]"
            label="Paroles"
            auto-grow
            outlined
            placeholder="Paroles"
          ></v-textarea>
        </v-col>
        <v-col cols="4">
          <div class="right-widget">
            <v-select
              v-model="fetchedSong.key"
              :disabled="isSaving"
              :items="keys"
              label="Clé"
              outlined
            ></v-select>
            <v-text-field
              v-model="fetchedSong.author"
              :disabled="isSaving"
              label="Auteur(s)"
              outlined
              placeholder="Auteur(s)"
            ></v-text-field>
            <v-text-field
              v-model="fetchedSong.copyright"
              :disabled="isSaving"
              label="Copyright"
              outlined
              placeholder="Copyright"
            ></v-text-field>
            <v-text-field
              v-model="fetchedSong.bpm"
              :disabled="isSaving"
              label="BPM"
              outlined
              placeholder="BPM"
            ></v-text-field>

            <v-divider></v-divider>

            <v-btn
              :loading="isSaving"
              class="mt-6"
              color="primary"
              large
              rounded
              width="100%"
              @click="save()"
            >
              <v-icon left>mdi-check</v-icon>
              VALIDER
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
export default {
  name: "adf-songs-form",
  props: {
    songId: {
      type: [String, Number]
    }
  },
  data() {
    return {
      formIsValid: true,
      isLoading: false,
      isSaving: false,
      keys: ["C", "C#", "D", "D#", "E", "F", "F#", "G", "G#", "A", "A#", "B"],
      fetchedSong: {
        key: "C"
      },
      rules: {
        required: value => !!value || "Champ obligatoire",
        counter: value => value.length <= 20 || "Max 20 characters"
      }
    };
  },
  mounted() {
    if (this.songId) {
      this.fetchSong();
    }
  },
  methods: {
    fetchSong() {
      if (!this.isLoading) {
        this.isLoading = true;
        let songId = this.songId;
        if (songId) {
          var url = `/api/chants/${songId}`;
          this.$http.get(`${url}.json`).then(response => {
            this.fetchedSong = response.data;
            this.isLoading = false;
          });
        }
      }
    },
    save() {
      if (!this.isSaving && this.$refs.form.validate()) {
        this.isSaving = true;
        let songId = this.songId;
        if (songId) {
          var url = `/api/chants/${songId}`;
          this.$http
            .put(`${url}.json`, {
              song: this.fetchedSong
            })
            .then(response => {
              this.fetchedSong = response.data;
              this.isSaving = false;
              this.$router.push({
                name: "songs_show",
                params: { song_id: this.songId.toString() }
              });
            });
        } else {
          // Create !
          var url = `/api/chants`;
          this.$http
            .post(`${url}.json`, {
              song: this.fetchedSong
            })
            .then(response => {
              this.fetchedSong = response.data;
              this.songId = this.fetchedSong.id;
              this.isSaving = false;
              this.$router.push({
                name: "songs_show",
                params: { song_id: this.songId.toString() }
              });
            });
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.right-widget {
  position: sticky;
  top: 90px;
}
</style>
