import axios from "axios";
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { accessTokensCreate } from "../api/access-tokens";
import { camelizeKeys, decamelizeKeys } from "xcase";
import store from "../store";
let at;

export const adfHttp = c => {
  const customAxios = axios.create({
    baseURL: "/",

    // `transformResponse` allows changes to the response data to be made before
    // it is passed to then/catch
    transformResponse: [
      data => {
        var parsedData = {};

        if (data) {
          try {
            parsedData = JSON.parse(data);
          } catch (error) {
            console.warn(
              `Could not parse data as JSON ! - this could be ok if you're downloading a file for ex`,
              error
            );
            return data;
          }
        }

        return camelizeKeys(parsedData);
      }
    ],
    ...c
  });

  customAxios.interceptors.request.use(
    function(config) {
      // const token = store.getters.token();
      const token = localStorage.getItem('currentAccessToken');
      if (!!at && at != token) {
        console.warn("AccessToken has changed !");
      }
      at = token;
      console.log("token is", token);
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    async function(err) {
      const originalRequest = error.config;
      console.warn("Something went wrong !", err);
      
      if (error.response) {
        if (error.response.status === 401) {
          console.warn(
            "Auth error, looking to refresh token ...",
            originalRequest.url
          );
        }

        // Means the supposed token refresh request failed
        if (
          error.response.status === 401 &&
          // originalRequest.url === 'http://13.232.130.60:8081/v1/auth/token') {
          originalRequest.url === `/oauth/token.json`
        ) {
          // Try to create a new token with any user credentials
          console.warn(
            "Could not refresh token - trying to create a new token with user credentials"
          );

          const { email, password } = await store.getters.currentUser();
          if (email && password) {
            console.log("will try to re-connect with", email);
            const { accessToken, refreshToken } = await accessTokensCreate({
              username: email,
              password
            });
            store.commit("setRefreshToken", refreshToken);
            store.commit("setToken", accessToken);
          }

          return Promise.reject(error);
        } else if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          const refreshToken = store.getters.refreshToken();
          return axios;
          accessTokensCreate(
            {
              refreshToken
            },
            "refreshToken"
          ).then(
            res => {
              return axios(originalRequest);
            },
            e => {
              console.warn(
                "Could not refresh token - redirecting for re-authentication",
                e
              );
              return Promise.reject(error);
            }
          );
        }
      }

      return Promise.reject(err);
    }
  );

  // customAxios.interceptors.response.use(
  //   function(config) {
  //     // Any status code that lie within the range of 2xx cause this function to trigger
  //     // Do something with response data
  //     return config;
  //   },
  //   async function(error) {
  //     const originalRequest = error.config;

  //     if (error.response) {
  //       if (error.response.status === 401) {
  //         console.warn(
  //           "Auth error, looking to refresh token ...",
  //           originalRequest.url
  //         );
  //       }

  //       // Means the supposed token refresh request failed
  //       if (
  //         error.response.status === 401 &&
  //         // originalRequest.url === 'http://13.232.130.60:8081/v1/auth/token') {
  //         originalRequest.url === `/oauth/token.json`
  //       ) {
  //         // Try to create a new token with any user credentials
  //         console.warn(
  //           "Could not refresh token - trying to create a new token with user credentials"
  //         );

  //         const { email, password } = await store.getters.currentUser();
  //         if (email && password) {
  //           console.log("will try to re-connect with", email);
  //           const { accessToken, refreshToken } = await accessTokensCreate({
  //             username: email,
  //             password
  //           });
  //           store.commit("setRefreshToken", refreshToken);
  //           store.commit("setToken", accessToken);
  //         }

  //         return Promise.reject(error);
  //       } else if (error.response.status === 401 && !originalRequest._retry) {
  //         console.log("Lauching a refresh token request");
  //         originalRequest._retry = true;
  //         // const refreshToken = store.getters.refreshToken();
  //         const refreshToken = localStorage.getItem('currentRefreshToken');
  //         // return axios;
  //         accessTokensCreate(
  //           {
  //             refreshToken
  //           },
  //           "refresh_token"
  //         ).then(
  //           accessToken => {
  //             axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
  //             customAxios.defaults.headers.get['Authorization'] = 'Bearer ' + accessToken;
  //             customAxios.defaults.headers.post['Authorization'] = 'Bearer ' + accessToken;
  //             customAxios.defaults.headers.delete['Authorization'] = 'Bearer ' + accessToken;
  //             customAxios.defaults.headers.put['Authorization'] = 'Bearer ' + accessToken;
  //             console.log("Got a brand new accessToken", accessToken);
  //             console.log("Relaunching original request", originalRequest);
  //             return customAxios(originalRequest);
  //           },
  //           e => {
  //             console.warn(
  //               "Could not refresh token - redirecting for re-authentication",
  //               e
  //             );
  //             return Promise.reject(error);
  //           }
  //         );
  //       }
  //     }

  //     return Promise.reject(error);
  //   }
  // );

  

  // Instantiate the interceptor
  createAuthRefreshInterceptor(customAxios, refreshAuthLogic);

  return customAxios;
};

const refreshAuthLogic = failedRequest => {
  const refreshToken = localStorage.getItem('currentRefreshToken');
  return accessTokensCreate(
    {
      refreshToken
    },
    "refresh_token"
  ).then(
    accessToken => {
    console.log("Got a brand new accessToken", accessToken);
    failedRequest.response.config.headers['Authorization'] = 'Bearer ' + accessToken;
    return Promise.resolve();
  })
};
