<template>
  <v-dialog v-model="isOpened" persistent width="500">
    <v-card class="mx-auto">
      <v-card-title class="title font-weight-regular justify-space-between">
        <span>{{ currentTitle }}</span>
        <!-- <v-avatar
          color="primary lighten-2"
          class="subheading white--text"
          size="24"
          v-text="step"
        ></v-avatar> -->
      </v-card-title>

      <v-window v-model="step">
        <v-window-item :value="1">
          <v-card-text>
            Merci d'avoir rejoins la communauté "Autour du Feu". Avant de
            commencer, si vous le shouaitez, vous pouvez personnaliser votre
            profil pour qu'il vous ressemble.
          </v-card-text>
        </v-window-item>

        <v-window-item :value="2">
          <v-card-text>
            <adf-users-avatar-changer
              ref="avatarChanger"
              @onUserAvatarSaved="onAvatarSaved"
            />
          </v-card-text>
        </v-window-item>

        <v-window-item :value="3">
          <v-card-text>
            <p>
              Renseignez si vous le souhaitez votre localisation afin de trouver
              une communauté proche de chez vous.
            </p>
            <v-autocomplete
              v-model="userLocation"
              :items="locationItems"
              :loading="isLocationLoading"
              :search-input.sync="geocodeSearch"
              item-text="display_name"
              label="Votre adresse ..."
              placeholder="Taper ici une adresse, ville ou région ..."
              prepend-icon="mdi-map-search-outline"
              no-filter
              return-object
              clearable
              :allow-overflow="false"
              :hide-no-data="true"
              no-data-text="Aucune adresse trouvée :("
            ></v-autocomplete>
          </v-card-text>
        </v-window-item>

        <v-window-item :value="4">
          <v-card-text>
            <ul class="mb-5">
              <li>
                Vos données personelles (nom, prénom, adresse, ...) sont à vous,
                ADF s'engage à ne pas les divulguer, ni les utiliser à des fins
                publicitaires
              </li>
              <li>
                ADF utilise des cookies afin d'améliorer l'expérience
                utilisateur
              </li>
            </ul>
            <p>
              En utilisant ce réseau, vous acceptez l’utilisation des cookies
              ainsi que les conditions générales d'utilisation.
            </p>
          </v-card-text>
        </v-window-item>

        <v-window-item :value="5">
          <v-card-text>
            <div class="text-center">
              <img
                src="https://media.giphy.com/media/kfCeAUOlKnfUI/source.gif"
              />
              <v-chip pill class="giphy-credit" absolute link>
                <a href="https://giphy.com/gifs/movie-funny-film-kfCeAUOlKnfUI"
                  >via GIPHY</a
                >
              </v-chip>
            </div>
          </v-card-text>
        </v-window-item>
      </v-window>

      <v-card-actions>
        <v-btn :disabled="step === 1" text @click="goBack()">
          Retour
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          ref="nextBtn"
          :disabled="isNextButtonDisabled"
          color="primary"
          depressed
          v-text="step == 4 ? 'ACCEPTER' : 'SUIVANT'"
          @click="goNext()"
        >
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import AdfUsersAvatarChanger from "./avatar_changer.vue";

export default Vue.component("adf-welcome", {
  components: {
    AdfUsersAvatarChanger,
  },
  data() {
    return {
      step: 1,
      isOpened: true,
      avatarIsUploading: false,

      // LOCATION
      userLocation: null,
      locations: [],
      isLocationLoading: false,
      geocodeSearch: null,
    };
  },

  unmounted() {
    // this.$store.commit("setUiStates", {
    //   hideMenus: false
    // })
  },
  methods: {
    onAvatarSaved() {
      this.goNext();
    },
    usersUpdateUrl() {
      let url = this.currentUser ? `/utilisateurs/${this.currentUser.id}` : "/";
      return url;
    },
    goBack: function() {
      switch (this.step) {
        case 2:
          this.avatarFile = null;
          this.avatarFileDataURL = null;
          this.$refs.avatarVueDropzone.removeAllFiles();
          this.step--;
          break;
        default:
          this.step--;
      }
    },
    goNext: function() {
      switch (this.step) {
        // AVATAR CROP
        case 2:
          console.log(
            "this.$refs.avatarChanger.isBusy",
            this.$refs.avatarChanger.isBusy
          );
          if (!this.$refs.avatarChanger.isBusy) this.step++;

          break;

        // USER LOCATION
        case 3:
          if (this.userLocation) {
            this.$http
              .put(`/api/utilisateurs/${this.currentUser.id}.json`, {
                user: {
                  location_name: this.userLocation.display_name,
                  latitude: this.userLocation.location[0],
                  longitude: this.userLocation.location[1],
                },
              })
              .then((response) => {
                this.step++;
              });
          } else {
            this.step++;
          }
          break;

        // WAS WELCOMED !
        case 5:
          this.$http
            .put(`/api/utilisateurs/${this.currentUser.id}.json`, {
              user: {
                was_welcomed: true,
              },
            })
            .then((response) => {
              let user = response.data;
              this.$store.commit("setCurrentUser", user);
              // Close dialog !
              this.isOpened = false;
            });
          break;
        default:
          if (!this.isNextButtonDisabled) {
            this.step++;
          }
      }
    },

    fetchEntriesDebounced(term) {
      // cancel pending call
      clearTimeout(this._timerId);

      // delay new call 500ms
      this._timerId = setTimeout(() => {
        // console.log("search", this.locations)
        // https://nominatim.openstreetmap.org/search?format=json&q=%226%20bis%20all%C3%A9e%20des%20albizzias%22&details=1

        // Items have already been loaded
        // if (this.locations.length > 0) return

        // Items have already been requested
        if (this.isLoading) return;

        this.isLoading = true;

        let url = `https://nominatim.openstreetmap.org/search?format=json&q=${term}`;

        // Lazily load input items
        fetch(url)
          .then((res) => res.json()) // First extract response body
          .then((res) => (this.locations = res))
          .catch((err) => {
            console.log(err);
          })
          .finally(() => (this.isLoading = false));
      }, 500);
    },
  },
  computed: {
    currentTitle() {
      switch (this.step) {
        case 1:
          return "Bienvenue !";
        case 2:
          return "Avatar";
        case 3:
          return "Localisation";
        case 4:
          return "Données personnelles";
        case 5:
          return "C'est partie ! 🚀";
        default:
          return "";
      }
    },
    isNextButtonDisabled: function() {
      var isDisabled = false;
      switch (this.step) {
        case 1:
          break;
        case 2:
          break;
        default:
        // isDisabled = true
      }
      return isDisabled;
    },
    locationItems() {
      let locations = this.locations.map((locationData) => {
        // const Description = locationData.Description.length > this.descriptionLimit
        //   ? locationData.Description.slice(0, this.descriptionLimit) + '...'
        //   : locationData.Description

        // return Object.assign({}, locationData, { Description })
        return {
          display_name: locationData.display_name,
          location: [locationData.lat, locationData.lon],
        };
      });

      return locations;
    },
    ...mapState({
      currentUser: "currentUser",
    }),
  },
  watch: {
    geocodeSearch(term) {
      if (!term) {
        return;
      }

      this.fetchEntriesDebounced(term);
    },
    step() {
      if (this.$refs.nextBtn) {
        this.$refs.nextBtn.$el.blur();
      }
    },
  },
});
</script>

<style lang="scss">
#cropper {
  width: 260px;
  height: 260px;
  overflow: hidden;
  position: relative;
  margin: 0 auto;
  border-radius: 8px;
  &.cropper-invisible {
    opacity: 0;
  }
}
.cropper-crop-box,
.cropper-view-box {
  border-radius: 50%;
}

.cropper-view-box {
  box-shadow: 0 0 0 1px #39f;
  outline: 0;
}

.cropper-face {
  background-color: inherit !important;
}

.cropper-dashed,
.cropper-point.point-se,
.cropper-point.point-sw,
.cropper-point.point-nw,
.cropper-point.point-ne,
.cropper-line {
  display: none !important;
}

.cropper-view-box {
  outline: inherit !important;
}
</style>
