<template>
  <v-row>
    <v-col sm="8" cols="12">
      <v-card flat outlined>
        <v-list-item>
          <v-list-item-avatar color="grey"></v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Our Changing Planet</v-list-item-title>
          </v-list-item-content>
          <v-list-item-avatar>
            <v-menu
              transition="slide-y-transition"
              bottom
              offset-y
              left
            >
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item>
                  <v-list-item-title>MODIFIER</v-list-item-title>
                </v-list-item>
                <v-list-item color="danger">
                  <v-list-item-title>SUPPRIMER</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item-avatar>
        </v-list-item>
        <v-img
          class="white--text"
          height="200px"
          src="https://cdn.vuetifyjs.com/images/cards/docks.jpg"
        >
        </v-img>
        <v-card-title>TEST</v-card-title>
      </v-card>
    </v-col>
    <v-col sm="4" cols="12">
      <v-card flat outlined>test</v-card>
    </v-col>
  </v-row>
</template>

<script>
import {mapState} from "vuex"
export default {
  data: function () {
    return {
    }
  },
  created: function() {
  },
  computed: {
    ...mapState({
      currentUser: "currentUser"
    })
  },
  mounted() {
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
</style>
