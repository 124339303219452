import algoliasearch from "algoliasearch";
const client = algoliasearch("1DLO0J4KJ0", "593fb6ae86b777c8f3111a101cbbf437");

const songIndex = client.initIndex("Song");

export const searchSongs = async (term) => {
  try {
    const response = await songIndex.search(term)  
    return response;
  }
  catch (e) {
    console.warn(e);
  }
  
}