import Vue from "vue";
import { decamelizeKeys } from "xcase";

export const songsShow = (songId, key, capo) => {
  return new Promise((resolve, reject) => {
    if (songId) {
      var url = `/api/chants/${songId}`;
      if (key) {
        url = `${url}/cle/${encodeURIComponent(key)}`;
        if (capo >= 0) {
          url = `${url}/capo/${capo}`;
        }
      }
      Vue.$http.get(`${url}.json`).then((response) => {
        // console.log("ooo", response.body);
        const song = response.data;
        resolve(song);
      });
    }
  });
}