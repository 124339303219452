import Vue from "vue";

import { decamelizeKeys } from "xcase";

export const usersCreate = async (user) => {
  const url = `/api/utilisateurs.json`;
  const response = await Vue.$http.post(url, decamelizeKeys(user));
  const u = response.data;
  return u;
};

export const usersShow = async (userId = "en-cours") => {
  const url = `/api/utilisateurs/${userId}.json`;
  const response = await Vue.$http.get(url);
  const user = response.data;
  return user;
};
