<template>
  <div>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="false"
      :enable-download="true"
      :preview-modal="false"
      :filename="filename"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-content-width="750px"
      pdf-orientation="portrait"
      ref="html2Pdf"
      v-if="fetchedSong"
      :html-to-pdf-options="htmlToPdfOptions"
      @hasDownloaded="autoCloseWindow"
    >
      <section slot="pdf-content">
        <span class="on-top">
          <adf-songs-show
            :enablePrintMode="true"
            :song="{ id: songId }"
            :embedMode="false"
          />
        </span>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";
import { songsShow } from "../../api/songs";
import adfSongsShow from "./show.vue";
export default {
  name: "adf-songs-exporter",
  components: { adfSongsShow, VueHtml2pdf },
  props: {
    songId: {
      type: String,
    },
  },
  data() {
    return {
      fetchedSong: {},
    };
  },
  mounted() {
    songsShow(this.songId).then((song) => {
      this.fetchedSong = song;
      console.log("fetchedSong", this.fetchedSong.title);
      this.$nextTick(() => {
        this.generateReport();
      });
    });
  },
  computed: {
    filename() {
      return `chant-${this.songId}`;
    },
    htmlToPdfOptions() {
      return {
        margin: 10,
        filename: this.filename,
        image: {
          type: "jpeg",
          quality: 1,
        },
        enableLinks: true,
        html2canvas: {},
        jsPDF: {},
      };
    },
  },
  methods: {
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    autoCloseWindow() {
      console.log("closing window");
      // Don't auto-close window for now
      // It can close window before download autorization dialog shows up (on Safari)
      // window.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.on-top {
  margin-top: -120px;
  background-color: "#FFFFFF";
}
</style>
