<template>
  <div class="pa-2 search-wrapper">
    <v-text-field
        :loading="loading"
        class="search-input no-messages"
        prepend-icon="mdi-magnify"
        placeholder="Rechercher un chant par titre, paroles, ou auteur"
        height="45px"
        v-model="songSearch"
    >
      <template slot="append-outer"
                v-if="currentUserHasRoles(['worship_leader', 'songs_manager'])"
      >
        <v-btn
            depressed
            small
            :to="{name: 'songs_new'}"
            color="primary"
            dark
            fab
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
    </v-text-field>
  </div>
</template>
<script>
import Vue from "vue"
import debounce from "debounce"
import {mapState, mapGetters} from "vuex"

export default Vue.component('adf-songs-search', {
  props: {
    loading: {
      type: Boolean
    }
  },
  data() {
    return {
      songSearch: null
    }
  },
  computed: {
    ...mapGetters([
      "currentUserHasRoles"
    ]),
  },
  watch: {
    songSearch(songSearch, songSearchWas) {
      this.$emit('songs:search', songSearch, songSearchWas)
    }
  }
})
</script>
<style lang="scss">
.search-input {
  font-size: 28px;
  font-weight: lighter;

  .v-input__icon--prepend i {
    font-size: 40px;
    margin-top: 25px;
  }

  input {
    max-height: 45px !important;
  }
}
</style>
<style lang="scss" scoped>
.search-wrapper {
  position: sticky;
  top: 65px;
  z-index: 1;
  background: #fafafae0;
  .search-input {
    padding: 10px 15px;
  }
}

@media (max-width: 768px) {
  .search-input {
    top: 55px;
    font-size: 20px;
  }
}
</style>
