<template>
  <div :class="`align-${align} lds-ellipsis position-` + position">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</template>

<script>
import Vue from "vue"
import { mapState } from 'vuex'
export default Vue.component('adf-loader', {
  props: {
    position: {
      type: String,
      default: "absolute"
    },
    align: {
      type: String
    }
  }
})
</script>

<style lang="scss">
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  &.position-absolute {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -32px;
    margin-left: -32px;
  }
  &.align-center {
    left: 50%;
    margin-left: -32px;
  }
}
.lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50% !important;
  background: #fb4b00;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}
</style>
