/* eslint no-console: 0 */
// Run this example by adding <%= javascript_pack_tag 'hello_vue' %> (and
// <%= stylesheet_pack_tag 'hello_vue' %> if you have styles in your component)
// to the head of your layout file,
// like app/views/layouts/application.html.erb.
// All it does is render <div>Hello Vue</div> at the bottom of the page.

import Vue from "vue";
import Vuetify from "./app/plugins/vuetify";
import VueMoment from "vue-moment";
import VueRouter from "vue-router";
import Main from "./app/components/main.vue";
import VueAxios from "vue-axios";
import "./app/assets/stylesheets/app.scss";
import "./app/assets/stylesheets/print.scss";

import "./app/plugins/vue_router";
import { adfHttp } from "./app/plugins/vue_axios";
import "./app/plugins/vue_masonry";

// COMPONENTS
import "./app/components/navigation.vue";
import "./app/components/app_bar/app_bar.vue";
import "./app/components/shared/loader.vue";
// COMPONENTS

// FILTERS
import "./app/filters/string";
// FILTERS

import routes from "./app/routes";
import { setupRouting } from "./app/routes";
import store from "./app/store";
import "./app/helpers/string";

import moment from "moment";
import "moment/locale/fr";

Vue.use(VueMoment, {
  moment,
});

document.addEventListener("DOMContentLoaded", () => {
  const router = new VueRouter({
    routes, // short for `routes: routes`
    hashbang: false,
  });

  setupRouting(router);

  Vue.router = router;

  Vue.use(VueAxios, adfHttp());

  const app = new Vue({
    name: "Autour du Feu ",
    vuetify: Vuetify,
    router,
    store,
    render: (h) => h(Main),
    mounted() {
      //do something after mounting vue instance
      this.$store.commit("setUiStates", { isReady: true });
    },
    watch: {
      $route(to, from) {
        if (to.meta.uiOptions) {
          this.$store.commit("setUiOptions", to.meta.uiOptions);
        } else {
          // Default uiOptions
          this.$store.commit("setUiOptions", {
            noTopMargin: false,
          });
        }
      },
    },
    created() {
      console.log("app launched");
      // this.$auth.login({
      //   body: { username: 'dfabreguette@gmail.com', password: 'qsdfqsdf'},
      //   rememberMe: true,
      //   // fetchUser: false,
      //   // success: function() {
      //   //   console.log("SUCCESS !!")
      //   //   this.$http.get("utilisateurs/en-cours").then((res) => {
      //   //     console.log("res", res)
      //   //   })
      //   // }
      // });

      // Prepare Auth Headers
      // Vue.http.interceptors.push((request, next) => {
      //   console.log("ooo");
      //
      //   if (!this.$store.state.currentUser) {
      //
      //   }
      //   // request.headers.set('Authorization', 'Bearer eyJ0e.....etc')
      //   // request.headers.set('Accept', 'application/json')
      //   next()
      // });

      // this.$http.get("/api/utilisateurs/en-cours.json")
      // .then((response) => {
      //   console.log("response", response)
      // }, (error) => {
      //   console.log("error", error)
      // })
    },
  }).$mount();

  document.body.appendChild(app.$el);
});

// The above code uses Vue without the compiler, which means you cannot
// use Vue to target elements in your existing html templates. You would
// need to always use single file components.
// To be able to target elements in your existing html/erb templates,
// comment out the above code and uncomment the below
// Add <%= javascript_pack_tag 'hello_vue' %> to your layout
// Then add this markup to your html template:
//
// <div id='hello'>
//   {{message}}
//   <app></app>
// </div>

// import Vue from 'vue/dist/vue.esm'
// import App from '../app.vue'
//
// document.addEventListener('DOMContentLoaded', () => {
//   const app = new Vue({
//     el: '#hello',
//     data: {
//       message: "Can you say hello?"
//     },
//     components: { App }
//   })
// })
//
//
//
// If the project is using turbolinks, install 'vue-turbolinks':
//
// yarn add vue-turbolinks
//
// Then uncomment the code block below:
//
// import TurbolinksAdapter from 'vue-turbolinks'
// import Vue from 'vue/dist/vue.esm'
// import App from '../app.vue'
//
// Vue.use(TurbolinksAdapter)
//
// document.addEventListener('turbolinks:load', () => {
//   const app = new Vue({
//     el: '#hello',
//     data: () => {
//       return {
//         message: "Can you say hello?"
//       }
//     },
//     components: { App }
//   })
// })
