<template>
  <v-navigation-drawer
    fixed
    clipped
    bottom
    :expand-on-hover="!mobile"
    :class="`${!mobile ? ' top-padded' : ''} d-print-none`"
    v-model="isOpened"
    @transitionend="transitionend"
    hide-overlay>

    <v-list
      class="pl-0"
      :shaped="!mobile"
      dense
      :nav="!mobile"
      >
      <adf-app-bar-search
        :full-width="true"
        v-if="$vuetify.breakpoint.mobile"
        class="mb-2 fill-width"></adf-app-bar-search>
      <v-list-item
        color="primary"
        class="py-2 px-4"
        v-for="item in items"
        :key="item.title"
        :to="item.to"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import Vue from "vue"
export default Vue.component("adf-nav-drawer", {
  mounted() {
    // this.isOpened = !this.$vuetify.breakpoint.mobile    
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.mobile
    }
  },
  beforeRouteEnter(next, to, from) {
    next(vm => {
      // vm.isOpened = !vm.$vuetify.breakpoint.mobile
    })
  },
  data: () => ({
    isOpened: false,
    items: [
      {icon: "mdi-view-dashboard", title: "TABLEAU DE BORD", to: {name: "dashboard"}},
      {icon: "mdi-music-box-multiple", title: "CHANTS", to: {name: "songs_index"}},
      // {icon: "mdi-account-group", title: "COMMUNAUTÉ", to: {name: "community"}},
      // {icon: "mdi-cog", title: "PARAMÈTRES", to: {name: "settings"}}
    ]
  }),
  methods: {
    transitionend(e) {
      // console.log("transitionend", e)
    }
  }
})
</script>
<style lang="scss" scoped>
.v-navigation-drawer {
  &.top-padded {
    padding-top: 80px;
  }
  // When in mini variant mode

  .v-list-item {
    .v-list-item__icon {
      color: #ff440c !important;
    }
  }
  &.v-navigation-drawer--mini-variant {
    // Hide item background
    .v-list-item--active {
      &:before {
        background: none;
      }
    }
  }
}
</style>
