String.isPresent = function(s) {
  return !s || !(s.length === 0 || !s.trim());
};

export const emailRegExp = new RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
);
export const urlRegExp = new RegExp(
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
);

String.prototype.capitalize = function() {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

String.prototype.uppercase = function() {
  return this.toUpperCase();
};

String.prototype.isUrl = function() {
  return !!this && urlRegExp.test(this);
};

String.prototype.isEmail = function() {
  return !!this && emailRegExp.test(this);
};

export default String;
