<template>
  <v-dialog
    v-model="dialog"
    width="500"
  >
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on" @click.stop>
        <v-icon>mdi-delete-outline</v-icon>
      </v-btn>
    </template>

    <v-card v-if="dialog">
      <v-card-title
        class="headline"
        primary-title
      >
        Suppression
      </v-card-title>

      <v-card-text>
        Voulez-vous vraiment supprimer cette liste de chant ?
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn
          outlined
          rounded
          @click="dialog = false"
          :loading="destroying"
        >
          NON
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          outlined
          rounded
          @click="destroy"
          :loading="destroying"
        >
          OUI
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "adf-song-sets-destroy",
  props: {
    songSet: {
      type: Object,
      required: true
    }
  },
  data () {
    return  {
      dialog: false,
      destroying: false
    }
  },
  mounted() {
    console.log("oij");
  },
  methods: {
    destroy() {
      if (!this.loading) {
        let url = `/api/listes-de-chants/${this.songSet.id}.json`
        this.$http.delete(url)
        .then(() => {
          this.dialog = false
          this.$emit("songSets:destroyed")
        })
      }
    }
  }
}
</script>
