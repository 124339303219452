<template>
  <vue-dropzone
    @vdropzone-success="fileUploaded"
    @vdropzone-removed-file="removeFile"
    ref="mediaDropZone"
    id="dropzone" :options="dropzoneOptions"></vue-dropzone>
</template>
<script>
import Vue from "vue"
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import {camelizeKeys} from "xcase"
export default Vue.component("adf-medias-form", {
  props: {
  },
  components: {
    vueDropzone: vue2Dropzone
  },
  data: () => ({
    dialog: false,
    dropzoneOptions: {
      url: '/api/medias.json',
      thumbnailWidth: 150,
      maxFilesize: 0.5,
      dictDefaultMessage: "AJOUTEZ VOTRE FICHIER",
      paramName: "media[attachment]",
      addRemoveLinks: true,
      dictCancelUpload: "ANNULER",
      dictCancelUploadConfirmation: "ANNULER",
      dictRemoveFile: "SUPPRIMER",
      maxFilesize: "15000",
      headers: { "Authorization": `Bearer ${localStorage.getItem('auth_token_default')}` }
    }
  }),
  methods: {
    parseMedia(file) {
      if (file?.xhr?.response) {
        try {
          const media = camelizeKeys(JSON.parse(file.xhr.response))
          return media
        }
        catch(e) {
          console.warn(e);
        }
      }
      return null
    },
    removeFile(file) {
      const media = this.parseMedia(file)
      if (media)
        this.$http.delete(`/api/medias/${media.id}.json`)
    },
    fileUploaded(file) {
      const media = this.parseMedia(file)
      if (media)
        this.$emit("medias:file-added", media)
    }
  }
})
</script>
