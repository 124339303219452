<template>
  <span>
    <adf-songs-search
      :loading="isLoading"
      v-if="searchBar"
      @songs:search="onSongSearch"
    />
    <v-list class="songs-list">
      <v-list-item-group v-model="selectedSongIds" multiple>
        <adf-songs-item
          v-for="song in songs"
          :is-selected="isSelected(song)"
          :song="song"
          :key="song.id"
          class="px-4"
          @click="onSongClick(song)"
        >
          <template v-slot:append-action>
            <slot
              name="append-action"
              :is-selected="(preSelectedSongIds || []).includes(song.id)"
            ></slot>
          </template>
        </adf-songs-item>
        <v-list-item v-if="isLoading" justify-center>
          <adf-loader position="relative" align="center"></adf-loader>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </span>
</template>

<script>
import debounce from "debounce";
import { mapState, mapGetters } from "vuex";
import { EventBus } from "../../event-bus";
import adfSongsItem from "./partials/item.vue";
import adfSongsSearch from "../songs/search.vue";
export default {
  name: "adf-songs-index",
  props: {
    searchBar: {
      type: Boolean,
      default: true,
    },
    onSongSelect: {
      type: Function,
    },
    onSongUnselect: {
      type: Function,
    },
    songSearch: {
      type: String,
    },
    songSetPreference: {
      type: Object,
    },
    preSelectedSongIds: {
      type: Array,
    },
  },
  data() {
    return {
      songs: [],
      selectedSongIds: [],
      selectedSong: null,
      page: 0,
      noMorePages: false,
      isLoading: false,
    };
  },
  components: {
    adfSongsItem,
    adfSongsSearch,
  },
  created() {},
  computed: {
    ...mapGetters(["currentUserHasRoles"]),
    ...mapState({
      currentUser: "currentUser",
    }),
  },
  mounted() {
    this.setupWindowScrolling();
    this.search();
  },
  beforeMount() {
    this.selectedSongIds = [...(this.preSelectedSongIds || [])];
  },
  beforeDestroy() {
    this.$store.commit("setUiStates", { bodyStyles: {} });
  },
  methods: {
    onSongClick(song) {
      if (this.onSongSelect || this.onSongUnselect) {
        // Song click is executed before selectedSongIds udpate
        if (this.onSongUnselect && this.isSelected(song)) {
          this.onSongUnselect(song);
        } else if (this.onSongSelect) {
          this.onSongSelect(song);
        }
      } else if (song) {
        this.$router.push({
          name: "songs_show",
          params: { song_id: song.id, song },
        });
      }
    },
    onSongSearch(songSearch, songSearchWas) {
      // this.songSearch = songSearch
      console.log("onSongSearch-in", songSearch);
      if (this.songSearch) this.$emit("update:songSearch", songSearch);
      else
        this.debouncedSongSearch(songSearch, {
          reset: songSearchWas != songSearch,
        });
    },
    isSelected(song) {
      return this.selectedSongIds.includes(song.id);
    },
    search(customSongSearch, opts) {
      if (!opts) opts = {};
      if (this.isLoading) return;

      if (opts.reset) {
        this.noMorePages = false;
        this.page = 0;
      }
      const searchTerm = customSongSearch || this.songSearch;

      // this.selectedSongIds = [];
      this.page++;

      if (!this.noMorePages && !this.isLoading) {
        this.isLoading = true;
        this.searchRefreshLock = true;
        this.$http
          .get(
            `/api/chants/recherche/${
              searchTerm ? `/titre/${searchTerm}` : ""
            }/page/${this.page}.json`
          )
          .then((res) => {
            this.isLoading = false;
            // this.$emit("loading", false);
            var x = window.scrollX;
            var y = window.scrollY;
            let newItems = res.data.items;
            if (this.page == 1) {
              // this.selectedSongIds = [];
              this.songs = newItems;
            } else {
              if (newItems.length == 0) {
                this.noMorePages = true;
              }
              this.songs = this.songs.concat(newItems);
            }
            this.$nextTick(() => {
              window.scrollTo(x, y);
              this.searchRefreshLock = false;
            });
          });
      }
    },
    setupWindowScrolling() {
      EventBus.$on("scroll", this.onScroll);
    },
    onScroll(scrollTop) {
      let bottomOfWindow =
        scrollTop + window.innerHeight >=
        document.documentElement.offsetHeight - 150;

      if (bottomOfWindow && !this.noMorePages) {
        this.search();
      }
    },
    debouncedSongSearch: debounce(function (songSearch, songSearchWas) {
      console.log("new song search", songSearch);
      this.search(songSearch, { reset: true });
    }, 800),
  },
  watch: {
    isLoading(isLoading) {
      this.$emit("loading", isLoading);
    },
    songSearch(songSearch) {
      this.debouncedSongSearch(songSearch);
    },
    preSelectedSongIds(preSelectedSongIds) {
      this.selectedSongIds = preSelectedSongIds;
    },
  },
};
</script>

<style lang="scss">
.search-input {
  font-size: 28px;
  font-weight: lighter;
  .v-input__icon--prepend i {
    font-size: 40px;
    margin-top: 25px;
  }
}
// #song-shortcut {
//   position: sticky;
//   top: 83px;
// }
// #song-list {
//   .v-list-item {
//     &.v-item--active {
//       position: sticky;
//       top: 83px;
//       background: #fff;
//       z-index: 1;
//     }
//   }
// }
</style>
<style land="scss" scoped>
.search-input {
  position: sticky;
  top: 65px;
  z-index: 1;
  background: #fafafae0;
  padding: 10px 15px;
}
@media (max-width: 768px) {
  .search-input {
    top: 55px;
    font-size: 20px;
  }
}
</style>
