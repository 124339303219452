<template>
  <div>
    <v-dialog v-model="dialog" width="500" scrollable>
      <template v-slot:activator="{ on }">
        <slot name="add-button" :on="on"></slot>
      </template>

      <adf-song-set-preferences-destroy
        :hidden="true"
        @destroyed="onSongSetPreferenceDestroyed"
        ref="adfSongSetPreferencesDestroy"
      />

      <v-card v-if="dialog">
        <v-card-title class="headline px-4" primary-title>
          Ajouter des chants
        </v-card-title>

        <v-card-text
          style="height: 300px;"
          class="pa-0"
          v-on:scroll.passive="onDialogScroll"
        >
          <v-list class="top-search-zone py-0">
            <v-list-item class="py-0">
              <v-text-field
                :loading="loading"
                class="py-0"
                append-icon="mdi-magnify"
                placeholder="Rechercher par titre, paroles ou auteur"
                v-model="songSearch"
                :autofocus="true"
                ref="searchInput"
              />
            </v-list-item>
          </v-list>
          <adf-songs-index
            :pre-selected-song-ids="selectedSongIds"
            :search-bar="false"
            :song-search.sync="songSearch"
            :on-song-select="onSongSelect"
            :on-song-unselect="onSongUnselect"
            @loading="setLoading"
            ref="songsIndex"
          >
            <template v-slot:append-action="appendActionProps">
              <v-list-item-icon>
                <v-btn icon v-if="appendActionProps.isSelected">
                  <v-icon>mdi-check</v-icon>
                </v-btn>
                <!-- <v-btn icon v-else-if="appendActionProps.isSelected">
                  <v-icon>mdi-refresh</v-icon>
                </v-btn> -->
                <v-btn icon v-else>
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-list-item-icon>
            </template>
          </adf-songs-index>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            rounded
            @click="close"
            :loading="saving"
          >
            TERMINER
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Vue from "vue";
import adfSongsIndex from "../songs/index.vue";
import adfSongSetPreferencesDestroy from "./destroy.vue";
import { camelizeKeys, decamelizeKeys } from "xcase";
export default {
  name: "adf-song-set-prefrences-form",
  components: { adfSongsIndex, adfSongSetPreferencesDestroy },
  data() {
    return {
      dialog: false,
      saving: false,
      songSearch: null,
      loading: false,
      newSelectedSongPreferences: [],
    };
  },
  props: {
    songSetId: {
      type: String,
      required: true,
    },
  },
  computed: {
    selectedSongIds() {
      return this.newSelectedSongPreferences.map((ssp) => ssp.song.id);
    },
  },
  mounted() {},
  methods: {
    setLoading(loading) {
      console.log("loading is", loading);
      this.$nextTick(() => {
        this.loading = loading;
      });
    },
    onDialogScroll(e) {
      this.$refs.songsIndex.onScroll(e.target.scrollTop);
    },
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$emit("close", this.newSelectedSongPreferences);
    },
    onSongSetPreferenceDestroyed(destroyedSongSetPreference) {
      let songSetPreferenceIndex = this.newSelectedSongPreferences.findIndex(
        (songSetPreference) =>
          songSetPreference.id == destroyedSongSetPreference.id
      );
      this.$delete(this.newSelectedSongPreferences, songSetPreferenceIndex);
    },
    onSongUnselect(unselectedSong) {
      if (unselectedSong) {
        const unselectedSongPreference = this.newSelectedSongPreferences.find(
          (songSetPrefererence) =>
            songSetPrefererence.song.id == unselectedSong.id
        );
        if (unselectedSongPreference)
          this.$refs.adfSongSetPreferencesDestroy.destroy(
            unselectedSongPreference
          );
        else console.warn("could not find unselected song set preference !");
      }
    },
    onSongSelect(selectedSong) {
      console.log("onSongSelect", selectedSong);
      if (selectedSong) {
        let url = `/api/listes-de-chants/${this.songSetId}/preferences-de-chants.json`;
        this.$http
          .post(url, {
            song_set_preference: {
              song_id: selectedSong.id,
            },
          })
          .then((response) => {
            let songSetPreference = camelizeKeys(response.data);
            this.newSelectedSongPreferences.push(songSetPreference);
            Vue.set(selectedSong, "songSetPreference", songSetPreference);
          });
      }
    },
  },
  watch: {
    dialog(isOpened) {
      if (isOpened) {
        this.newSelectedSongPreferences = [];
        setTimeout(() => {
          this.$refs.searchInput.focus();
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.top-search-zone {
  position: sticky;
  top: 0;
  background: #ffffffe3;
  z-index: 1;
  backdrop-filter: blur(1px);
}
</style>
