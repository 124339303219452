<template>
  <div>
    <adf-alerts></adf-alerts>
    <v-scale-transition origin="center">
      <v-btn
          class="navigation-btn right d-none d-md-block"
          fab
          icon
          @click="goToSongs"
          v-if="dashboardPane < 2"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-scale-transition>

    <v-scale-transition origin="center">
      <v-btn
          class="navigation-btn left d-none d-md-block"
          fab
          icon
          @click="goToDashboard"
          v-if="dashboardPane > 1"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
    </v-scale-transition>

    <adf-songs-search
        @songs:search="onSongSearch"
    />

    <v-window v-model="dashboardPane" class="mt-8">
      <v-window-item :value="1">
        <v-row>
          <v-col sm="6" class="pt-0">
            <adf-song-sets/>
          </v-col>
          <v-col sm="6" class="pt-0">
            <adf-songs-latests/>
          </v-col>
        </v-row>
      </v-window-item>
      <v-window-item :value="2">
        <v-row>
          <v-col xs="12" sm="12" id="song-list" class="pt-0">
            <v-card>
              <adf-songs-index :search-bar="false" :song-search="songsSearch"/>
            </v-card>
          </v-col>
        </v-row>
      </v-window-item>
      <!--      <v-window-item :value="3">-->
      <!--        <v-row>-->
      <!--          <v-col xs="12" sm="12" id="community" class="pt-0">-->
      <!--            <v-card>-->
      <!--              <v-card-title>Communauté !</v-card-title>-->
      <!--            </v-card>-->
      <!--          </v-col>-->
      <!--        </v-row>-->
      <!--      </v-window-item>-->
    </v-window>
  </div>
</template>
<script>
import Vue from "vue";
import adfSongsIndex from "../songs/index.vue";
import adfSongSets from "../song_sets/index.vue";
import adgSongsLatests from "../songs/latests.vue";
import {mapState, mapGetters} from "vuex";
import adfAlerts from "../alerts/alerts.vue";

export default Vue.component("adf-dashboard", {
  props: {
    routeDashboardPane: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      songsSearch: null,
      dashboardPane: 1,
    };
  },
  mounted() {
    this.$store.commit("setUiStates", {
      bodyStyles: {
        background: "#FBFBFB",
      },
      hideMenus: false,
    });
  },
  beforeDestroy() {
    this.$store.commit("setUiStates", {
      bodyStyles: {
        background: "#FFFFFF",
      },
    });
  },
  components: {
    adfSongsIndex,
    adfSongSets,
    adfAlerts,
    adgSongsLatests,
  },
  methods: {
    goToSongs() {
      this.dashboardPane = 2;
      history.pushState({}, null, "/#/chants");
    },
    goToDashboard() {
      this.dashboardPane = 1;
      history.pushState({}, null, "/#/tableau-de-bord");
    },
    onSongSearch(songsSearch) {
      this.songsSearch = songsSearch;
      this.dashboardPane = 2;
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.dashboardPane = vm.routeDashboardPane;
      vm.$store.commit("setUiStates", {
        bodyStyles: {
          background: "#FBFBFB",
        },
        hideMenus: false,
      });
    });
  },
  computed: {
    ...mapGetters(["currentUserHasRoles"]),
    ...mapState({
      currentUser: "currentUser",
    }),
  },
  watch: {
    routeDashboardPane(routeDashboardPane) {
      this.dashboardPane = routeDashboardPane;
    },
  },
});
</script>
<style scoped lang="scss">
.v-window {
  overflow: visible;
  min-height: 60vh; // Makes sure swipe gesture is active on the whole page
}

.navigation-btn {
  position: fixed;
  z-index: 1;
  top: 50%;
  margin-top: 0;

  i.mdi {
    font-size: 50px;
  }

  $horizontal_offset: 50px;

  &.right {
    right: 30px;
  }

  &.left {
    left: 80px;
  }
}

@media (max-width: 1200px) {
  .navigation-btn {
    i.mdi {
      font-size: 50px;
    }

    $horizontal_offset: 0px;

    &.right {
      right: $horizontal_offset;
    }

    &.left {
      left: $horizontal_offset;
    }
  }
}
</style>
