<template>
  <v-card>
    <v-card-title>
      DERNIERS CHANTS PUBLIÉS
    </v-card-title>
    <span v-if="latestSongs.length > 0">
      <v-list-item-group v-model="selectedLatestSongIndex">
        <v-list-item
          v-for="(song, i) in latestSongs"
          rounded
          color="primary"
          :key="i"
        >
          <v-list-item-icon class="custom-list-icon">
            <v-chip
              :class="`chord-chip ${song.favoriteKey ? 'yellow darken-1' : ''}`"
            >
              <span v-if="song.favoriteKey">
                {{ displayKey(song.favoriteKey) }}
              </span>
              <span v-else-if="song.lastKey">
                {{ displayKey(song.lastKey) }}
              </span>
              <span v-else>
                {{ displayKey(song.key) }}
              </span>
            </v-chip>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ song.title }}
              <span class="author-info" v-if="String.isPresent(song.author)">
                - {{ song.author }}
              </span>
            </v-list-item-title>
            <v-list-item-subtitle>{{ song.sneakPeek }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="isLoading" justify-center>
          <adf-loader position="relative" align="center"></adf-loader>
        </v-list-item>
      </v-list-item-group>
    </span>
  </v-card>
</template>
<script>
import Vue from "vue";
import { camelizeKeys } from "xcase";
import adfLoader from "../shared/loader.vue";
export default Vue.component("adf-songs-latests", {
  components: {
    adfLoader,
  },
  data() {
    return {
      latestSongs: [],
      isLoading: false,
      selectedLatestSongIndex: null,
    };
  },
  mounted() {
    this.fetchLatestSongs();
  },
  methods: {
    displayKey(key) {
      return key.split("/")[0];
    },
    fetchLatestSongs() {
      if (!this.isLoading) {
        this.isLoading = true;
        this.$http.get(`/api/chants/recents.json`).then((res) => {
          this.isLoading = false;
          this.latestSongs = camelizeKeys(res.data.items);
        });
      }
    },
  },
  watch: {
    selectedLatestSongIndex(selectedSongIndex) {
      const selectedSong = this.latestSongs[selectedSongIndex];
      if (selectedSong)
        this.$router.push({
          name: "songs_show",
          params: { song_id: selectedSong.id, song: selectedSong },
        });
    },
  },
});
</script>
<style lang="scss" scoped>
.v-application--is-ltr .v-list-item__icon:first-child.custom-list-icon {
  margin-right: 15px;
}
.author-info {
  font-style: italic;
  font-size: 14px;
  color: #848484;
}
</style>
