<template>
  <div>
    <v-row
      v-touch="{
        left: () => previousSong(),
        right: () => nextSong(),
      }"
    >
      <v-col lg="3" cols="12" class="song-set-preferences d-print-none">
        <!-- <h1 class="main-title my-3" v-if="Object.keys(songSet).length > 0">
          {{ songSet.title }}
          <div class="subtitle-1 overline pt-1">
            Prévu pour le {{ songSet.dueTo | moment("DD/MM/YYYY") }}
          </div>
        </h1> -->

        <v-card v-if="songSetPreferences.length > 0" :loading="loading">
          <v-list class="py-0">
            <v-list-item>
              <v-list-item-content>
                <h3>
                  {{ songSet.title }}
                </h3>
              </v-list-item-content>

              <v-btn
                :class="`mr-1`"
                v-if="$vuetify.breakpoint.smAndDown"
                icon
                @click="songSetIsVisible = !songSetIsVisible"
              >
                <v-icon>mdi-format-list-numbered</v-icon>
              </v-btn>


              <v-btn icon @click="sendByEmail">
                <v-icon>mdi-email-arrow-right-outline</v-icon>
              </v-btn>
              
              <v-btn
                :class="`${editMode ? 'primary' : ''}`"
                icon
                :color="editMode ? 'white' : ''"
                @click="editMode = !editMode"
              >
                <v-icon>mdi-pencil-outline</v-icon>
              </v-btn>

              <adf-song-set-preferences-form
                @close="addSongsToSongSet"
                :song-set-id="songSetId"
                ref="songSetPreferencesForm"
              >
                <template v-slot:add-button="props">
                  <v-btn icon v-on="props.on">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
              </adf-song-set-preferences-form>
            </v-list-item>
            <adf-song-set-set
              v-if="$vuetify.breakpoint.mdAndUp || songSetIsVisible"
              :currentSongPreferenceId.sync="currentSongPreferenceId"
              :editMode.sync="editMode"
              :songSetPreferences.sync="songSetPreferences"
              @move="moveSongSet"
              @destroyed="onSongSetPreferenceDestroyed"
            />
          </v-list>
        </v-card>
        <div v-else>
          <adf-song-set-preferences-form
            @close="addSongsToSongSet"
            :song-set-id="songSetId"
            ref="songSetPreferencesForm"
          >
            <template v-slot:add-button="props">
              <v-btn icon color="primary" v-on="props.on">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
          </adf-song-set-preferences-form>
        </div>
      </v-col>
      <v-col lg="9" cols="12">
        <adf-songs-show
          ref="song"
          @selectedKey="onSelectedKey"
          @selectedCapo="onSelectedCapo"
          :key-is-locked="keyIsLocked"
          :song="currentSong"
          :embed-mode="true"
          :show-title="true"
          v-if="currentSong"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { camelizeKeys, decamelizeKeys } from "xcase";
import adfSongsShow from "../songs/show.vue";
import adfSongSetPreferencesForm from "../song_set_preferences/form.vue";
import adfSongSetSet from "./set.vue";
import { EventBus } from "../../event-bus.js";
import { mapState, mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "adf-song-sets-show",
  props: {
    songSetId: {
      type: String,
      required: true,
    },
  },
  components: {
    adfSongsShow,
    adfSongSetPreferencesForm,
    adfSongSetSet,
  },
  data() {
    return {
      songSet: {},
      songSetPreferences: [],
      currentSong: null,
      currentSongPreferenceId: null,
      currentSongPreference: null,
      noMorePages: false,
      page: 0,
      loading: false,
      editMode: false,
      songSetIsVisible: false,
    };
  },
  watch: {
    currentSongPreferenceId(currentSongPreferenceId) {
      this.loadCurrentSongPreference();
      this.$store.commit("setSongSetsStorage", {
        id: this.songSetId,
        currentSongPreferenceId: currentSongPreferenceId,
      });
      this.$nextTick(() => this.$refs.song?.fetchActivities({ reset: true }));
    },
  },
  mounted() {
    this.page = 0; // Reset page defaults
    this.noMorePages = false; // Reset page defaults
    this.$store.commit("setUiOptions", { noTopMargin: true });
    this.$store.commit("setUiStates", {
        bodyStyles: { },
      });
    this.fetchSongSet();
    this.fetchPreferences();
    this.setupScrollFeature();
    this.currentSongPreferenceId =
      this.songSetSession.currentSongPreferenceId || 0;
  },
  beforeDestroy() {
    this.$store.commit("setUiOptions", { noTopMargin: false });
  },
  computed: {
    keyIsLocked() {
      return !this.currentUser || (this.currentUser && this.currentUser.id != this.songSet.userId);
    },
    songSetSession() {
      return this.$store.getters.findSongSetStorage(this.songSetId) || {};
    },
    currentSongIndex() {
      return this.songSetPreferences.findIndex(
        (ssp) => ssp.id == this.currentSongPreferenceId
      );
    },
    ...mapState({
      currentUser: "currentUser",
      // 'isReady': (uiStates) => {
      //   console.log("uuu", uiStates.isReady)
      //   return uiStates.isReady
      // }
    }),
  },
  methods: {
    sendByEmail() {
      const subject = `${this.songSet.title} pour le ${moment(this.songSet.dueTo).format("DD/MM/YYYY")}`
      const setUrl = `https://www.autour-du-feu.fr/#/listes-de-chants/${this.songSetId}`;
      let body = `Salut,\n\nvoici une liste de chants qui devraient t'intéresser :\n\n`;
      body += this.songSetPreferences.map(songPref => `- ${songPref.song.title} en ${songPref.key}`).join("\n");
      body += `\n\nTu trouveras les paroles, accords et liens youtube ici :\n${setUrl}`
      body += '\n\nÀ très bientôt sur ADF !'
      window.open(`mailto:?subject=${subject}&body=${encodeURIComponent(body)}`);
    },
    nextSong() {
      console.log("currentSongIndex", this.currentSongIndex);
    },
    previousSong() {},
    updateSongPreferences(newSongSetPreferences) {
      this.songSetPreferences = newSongSetPreferences.concat();
      console.log(
        "songSetPreferences",
        this.songSetPreferences.map((ssp) => ssp.song.title)
      );
      console.log(
        "newSongSetPreferences",
        newSongSetPreferences.map((ssp) => ssp.song.title)
      );
    },
    loadCurrentSongPreference() {
      const currentSongPreference = this.songSetPreferences.filter(
        (ssp) => ssp.id == this.currentSongPreferenceId
      )[0];
      if (currentSongPreference) {
        this.currentSongPreference = currentSongPreference;
        if (this.currentSongPreference) {
          this.currentSong = this.currentSongPreference.song;
        }
      } else {
        this.currentSongPreference = null;
        this.currentSong = null;
      }
    },
    onSelectedKey(key) {
      // Update current song preference with given key
      if (this.currentSongPreference) {
        this.saveSongSetPreference(this.currentSongPreference.id, {
          key: key,
        });
      }
    },
    onSelectedCapo(capo) {
      // Update current song preference with given capo
      if (this.currentSongPreference) {
        this.saveSongSetPreference(this.currentSongPreference.id, {
          capo: capo,
        });
      }
    },
    moveSongSet({ id, attributes }) {
      console.log("move !!", id, attributes);
      this.saveSongSetPreference(id, attributes);
    },
    saveSongSetPreference(id, attributes) {
      if (!this.currentUser) {
        const songPreferenceIndex = this.songSetPreferences.findIndex(
          (sp) => sp.id == id
        );
        const songPreference = this.songSetPreferences[songPreferenceIndex];
        this.$set(
          this.songSetPreferences,
          songPreferenceIndex,
          {
            ...songPreference,
            ...attributes
          }
        );
        console.log("new pref", {
            ...songPreference,
            ...attributes
          });
        return;
      }

      let url = `/api/listes-de-chants/${this.songSetId}/preferences-de-chants/${id}.json`;
      this.$http
        .put(url, {
          song_set_preference: attributes,
        })
        .then((res) => {
          let updatedSongPreference = camelizeKeys(res.data);
          let songPreferenceIndex = this.songSetPreferences.findIndex(
            (sp) => sp.id == id
          );
          console.log("song pref", this.songSetPreferences);
          this.$set(
            this.songSetPreferences,
            songPreferenceIndex,
            updatedSongPreference
          );
        });
    },
    onSongSetPreferenceDestroyed(songSetPreference) {
      const songSetPreferenceIndex = this.songSetPreferences.findIndex(
        (ssp) => ssp.id == songSetPreference.id
      );
      this.$delete(this.songSetPreferences, songSetPreferenceIndex);
      this.$nextTick(() => {
        // If this was the last song
        if (this.songSetPreferences.length == 0) {
          // clean-up previously selected song
          this.loadCurrentSongPreference();
          // quit edit-mode
          this.editMode = false;
          // and automatically re-open "Add Song" module
          this.$refs.songSetPreferencesForm.open();
        } else if (this.currentSongPreferenceId == songSetPreference.id) {
          this.currentSongPreferenceId = this.songSetPreferences[0].id;
        }
      });
    },
    addSongsToSongSet(newSongPreferences) {
      // Add new songs to the list
      newSongPreferences.forEach((newSongPreference, i) => {
        this.songSetPreferences.push(newSongPreference);
      });

      // If this is first song selection,
      // Auto-select the first song by reloading current song preference
      // (currentSongPreferenceId should already be at 0)
      if (this.currentSongIndex == 0) {
        this.loadCurrentSongPreference();
      }
    },
    fetchSongSet() {
      if (this.songSetId) {
        let url = `/api/listes-de-chants/${this.songSetId}`;
        this.$http.get(url).then((res) => {
          this.songSet = camelizeKeys(res.data);
        });
      }
    },
    fetchPreferences(opts) {
      if (!opts) opts = {};

      if (opts.reset) {
        this.noMorePages = false;
        this.page = 0;
      }

      if (!this.noMorePages && !this.loading) {
        this.page++;
        this.loading = true;
        let url = `/api/listes-de-chants/${this.songSetId}/preferences-de-chants/page/${this.page}/par/16.json`;
        this.$http.get(url).then((res) => {
          this.loading = false;
          let newItems = camelizeKeys(res.data.items);
          this.totalCount = res.data.count;

          if (this.page == 1) {
            this.songSetPreferences = newItems;
            if (newItems.length == 0) {
              this.$refs.songSetPreferencesForm.open();
            } else {
              // if (!this.currentSong) this.currentSongIndex = 0;
              this.loadCurrentSongPreference();
            }
          } else {
            if (newItems.length == 0) {
              this.noMorePages = true;
            }
            this.songSetPreferences = this.songSetPreferences.concat(newItems);
          }
        });
      }
    },
    setupScrollFeature() {
      EventBus.$on("scroll", (scrollTop) => {
        let bottomOfWindow =
          scrollTop + window.innerHeight >=
          document.documentElement.offsetHeight - 150;
        if (bottomOfWindow && !this.noMorePages) {
          this.fetchPreferences();
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.main-title {
  line-height: 30px;
}
.song-set-item {
  .content.clipped {
    width: calc(100% - 30px);
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.chord-chip {
  padding: 0;
  width: 32px;
  text-align: center;
  display: inline-block;
}
</style>
