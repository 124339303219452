<template>
  <div>
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on }">
        <v-btn icon sm right v-on="on" id="song-sets-new">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>

      <v-card v-if="dialog">
        <v-form ref="form" lazy-validation>
          <v-card-title
            class="headline"
            primary-title
          >
            <span v-if="songSet.id">
              Modifier la liste "{{songSet.title}}"
            </span>
            <span v-else>
              Créer une liste de chant
            </span>
          </v-card-title>

          <v-card-text>
            <v-text-field
              v-model="songSet.title"
              label="Nom"
              prepend-icon="mdi-information-outline"
              class="mt-4"
              required
              :rules="formRules.title"
              :autofocus="true"
              ref="titleInput"
            ></v-text-field>

            <v-menu
              v-model="datePicker"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="computedDateFormatted"
                  label="Date d'échéance"
                  persistent-hint
                  prepend-icon="mdi-calendar"
                  v-on="on"
                  :rules="formRules.dueTo"
                  required
                ></v-text-field>
              </template>
              <v-date-picker locale="fr" v-model="songSet.dueTo" no-title @input="datePicker = false"></v-date-picker>
            </v-menu>

          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              id="song-sets-save"
              color="primary"
              outlined
              rounded
              @click="save"
              :loading="isSaving"
            >
              <span v-if="songSet.id">ENREGISTRER</span>
              <span v-else>CONTINUER</span>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {decamelizeKeys} from 'xcase'
import {mapGetters,mapState} from 'vuex'
export default {
  data (vm) {
    return {
      dialog: false,
      datePicker: false,
      focus: false,
      isSaving: false,
      formRules: {
        title: [
          t => !!t || 'Veuillez donner un nom à votre liste'
        ],
        dueTo: [
          d => !!d || 'Veuillez choisir une date d\'échéance',
          d => vm.validFormattedDate(d) || 'Veuillez choisir une date au format \'DD/MM/YYYY\''
        ]
      },
      songSet: vm.emptySongSet()
    }
  },
  computed: {
    computedDateFormatted: {
       get(){
         return this.formatDate(this.songSet.dueTo)
       },
       set(newDateFormat){
         if (newDateFormat)
         this.songSet.dueTo = this.parseDate(newDateFormat);
         return newDateFormat
       }
    },
    ...mapGetters([
      "currentUserHasRoles"
    ]),
    ...mapState({
      currentUser: "currentUser"
    })
  },

  watch: {
    dialog (isOpened) {
      if (isOpened) {
        setTimeout(() => {
          // If we're not in edit mode
          if (!this.songSet.id) {
            // Generate a blank initial songSet
            this.songSet = this.emptySongSet()
          }
          this.$refs.titleInput.focus()
          this.$refs.form.resetValidation()
        })
      }
      else {
        this.songSet = this.emptySongSet()
      }
    }
  },

  methods: {
    emptySongSet() {
      return Object.assign({}, {
        title: null,
        dueTo: new Date().toISOString().substr(0, 10),
        formattedDueTo: this.formatDate(new Date().toISOString().substr(0, 10))
      })
    },
    edit (songSet) {
      this.songSet = Object.assign({}, songSet)
      this.songSet.dueTo = this.songSet.dueTo.substr(0, 10)
      this.dialog = true
    },
    validFormattedDate(d) {
      // DD/MM/YYY
      return /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/.test(d)
    },
    validParsedDate(d) {
      // YYYY-MM-DD
      return /^\d{4}(-)(((0)[0-9])|((1)[0-2]))(-)([0-2][0-9]|(3)[0-1])$/.test(d)
    },

    // Format from "DD/MM/YYYY" to "YYYY-MM-DD"
    formatDate (date) {
      if (!date || !this.validParsedDate(date)) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    // Parse from "YYYY-MM-DD" to "DD/MM/YYYY"
    parseDate (date) {
      if (!date || !this.validFormattedDate(date)) return null

      const [day, month, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    save () {
      if (this.$refs.form.validate() && !this.isSaving) {
        this.isSaving = true
        this.songSet.userId = this.currentUser.id

        let url = `/api/listes-de-chants${this.songSet.id ? `/${this.songSet.id}` : ''}.json`;
        let onSuccess = (r) => {
          this.$emit("songSets:saved")
          this.dialog = false
          this.isSaving = false
        }

        if (this.songSet.id) {
          this.$http.put(url, {
            song_set: decamelizeKeys(this.songSet)
          })
          .then(onSuccess)
        }
        else {
          this.$http.post(url, {
            song_set: decamelizeKeys(this.songSet)
          })
          .then(onSuccess)
        }
      }
    }
  },
}
</script>
