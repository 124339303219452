<template>
	<v-navigation-drawer :app="true" dark v-model="drawer">
		<v-list dense nav class="py-0">
			<v-list-item two-line>
				<v-list-item-avatar>
					<!-- <img src="https://randomuser.me/api/portraits/men/81.jpg"> -->
					<v-img :src="currentUser.avatar.thumb" />
				</v-list-item-avatar>

				<v-list-item-content>
					<v-list-item-title>{{ currentUser.firstName }}</v-list-item-title>
					<!-- <v-list-item-subtitle>Subtext</v-list-item-subtitle> -->
				</v-list-item-content>
			</v-list-item>

			<v-divider></v-divider>

			<v-list-item
				v-for="item in items"
				:key="item.title"
				link
				@click="goTo(item.key)"
			>
				<v-list-item-icon>
					<v-icon>{{ item.icon }}</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title>{{ item.title }}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</v-list>
	</v-navigation-drawer>
</template>

<script>
	import Vue from "vue";
	import { mapState } from "vuex";
	import { accessTokensRevoke } from "../api/access-tokens";
	export default Vue.component("adf-navigation", {
		data() {
			return {
				drawer: false,
				items: [
					{ key: "main_feed", title: "Actualités", icon: "mdi-view-dashboard" },
					{ key: "logout", title: "Se déconnecter", icon: "mdi-logout" },
				],
			};
		},
		methods: {
			toggleDrawer() {
				this.drawer = !this.drawer;
			},
			goTo(key) {
				switch (key) {
					case "logout":
						console.log("LOGOUT ");
						accessTokensRevoke().then(() => {
							this.$store.commit("setCurrentUser", null);
							this.$store.commit("setUiStates", { hideMenus: false });
							this.$router.push({ name: "about" });
						});
						// this.$auth.logout()
						//   .then(() => {
						//   })
						break;
					default:
				}
			},
		},
		computed: {
			...mapState({
				currentUser: "currentUser",
			}),
		},
	});
</script>

<style scoped></style>
