<template>
  <div
    :style="uiStates.bodyStyles"
    :class="`main-wrapper ${isWrapperBlurred ? 'blurred' : ''}`"
  >
    <v-app
      v-cloak
      :class="uiStates.isReady ? 'ready' : ''"
      v-if="uiStates.isReady"
    >
      <v-main :class="appClasses">
        <adf-app-bar @toggleDrawer="toggleDrawer"
          v-if="!uiStates.hideMenus"></adf-app-bar>
        <!-- <adf-navigation ref="navigation" v-if="currentUser && !hideMenus"></adf-navigation> -->
        <adf-welcome
          v-if="currentUser && !currentUser.wasWelcomed"
        ></adf-welcome>
        <adf-nav-drawer
          ref="navDrawer"
          v-if="!uiStates.hideMenus"
        ></adf-nav-drawer>
        <v-container :class="mainClasses" v-if="hasADefaultRouteView()">
          <router-view></router-view>
        </v-container>
        <router-view name="fullWidth"></router-view>
      </v-main>
      <v-footer app class="d-print-none py-0"> </v-footer>
    </v-app>
    <div v-if="!uiStates.isReady">
      <adf-loader></adf-loader>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters } from "vuex";
import { EventBus } from "../event-bus.js";
import adfNavDrawer from "./app_bar/nav_drawer.vue";
import adfAppBar from "./app_bar/app_bar.vue";
import adfLoader from "./shared/loader.vue";
import adfWelcome from "./users/welcome.vue";
export default {
  components: {
    adfNavDrawer,
    adfAppBar,
    adfLoader,
    adfWelcome,
  },
  data: function() {
    return {
      test: true,
      isDrawerOpened: false,
      isWrapperBlurred: false,
    };
  },
  computed: {
    // isReady() {
    //   return this.$store.getters.getUiStates("isReady");
    // },
    // hideMenus() {
    //   return this.$store.getters.getUiStates("hideMenus");
    // },
    // noTopMargin() {
    //   return this.$store.getters.getUiOptions("noTopMargin");
    // },
    bodyStyles() {
      return this.uiStates.bodyStyles;
    },
    mobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    appClasses() {
      return `${!this.mobile ? "left-padded" : ""}`;
    },
    mainClasses() {
      return `main-container ${
        this.uiStates.noTopMargin ? "" : "mt-5 mt-sm-5"
      }`;
    },
    ...mapState({
      currentUser: "currentUser",
      uiStates: "uiStates",
    }),
  },
  methods: {
    toggleDrawer() {
      this.$refs.navDrawer.isOpened = !this.$refs.navDrawer.isOpened;
    },

    onScroll() {
      window.onscroll = () => {
        EventBus.$emit("scroll", document.documentElement.scrollTop);
      };
    },
    hasADefaultRouteView() {
      return (
        this.$route.matched &&
        this.$route.matched[0] &&
        this.$route.matched[0].components &&
        this.$route.matched[0].components &&
        this.$route.matched[0].components.default
      );
    },
  },
  created() {},
  mounted() {
    document.body.style.opacity = 1;
    this.onScroll();
    if (!localStorage.getItem("default_auth_token"))
      // this.$store.commit("setUiStates", {hideMenus: true});
      EventBus.$on("main-wrapper:toggle-blur", (isBlurred) => {
        this.isWrapperBlurred = isBlurred;
      });
    // this.$auth.load().then(() => {
    //   this.$auth.fetch().then((r) => {
    //     this.$store.commit("setCurrentUser", r.body)
    //     this.$store.commit("setUiStates", {
    //       isReady: true
    //     });
    //   })
    // })
  },
};
</script>

<style lang="scss">
.main-wrapper {
  #app {
    transition: all 1s ease-out;
  }
  &.blurred {
    #app {
      backdrop-filter: blur(20px);
    }
  }
}
</style>
