<template>
  <span v-if="Object.keys(dropzoneOptions).length > 0">
    <div id="cropper" v-if="avatarFile && avatarFileDataURL"
      :class="` ${avatarIsUploading ? 'cropper-invisible' : ''}`" >
      <v-btn
        class="crop-button"
        depressed
        small
        @click="cropAndUpload"
        color="primary"
        dark
        fab>
        <v-icon>mdi-check-bold</v-icon>
      </v-btn>
      <img ref="cropper" :src="avatarFileDataURL" >
    </div>

    <vue-dropzone v-show="!avatarFile && !avatarIsUploading"
      ref="avatarVueDropzone" id="dropzone"
      :options="dropzoneOptions"
      @vdropzone-thumbnail="onThumbnailGenerated"
      @vdropzone-success="onSuccess"></vue-dropzone>

    <adf-loader v-if="avatarIsUploading"></adf-loader>
  </span>
</template>
<script>
import Vue from "vue"
import { mapState } from 'vuex'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import "cropperjs/dist/cropper.css"
import Cropper from "cropperjs/dist/cropper.esm.js"
export default Vue.component("adf-users-avatar-changer", {
  components: {
    vueDropzone: vue2Dropzone
  },
  data () {
    return {
      cropper: null,
      avatarFile: null,
      avatarFileDataURL: null,
      cropImage: null,
      zoomLock: false,
      avatarIsCropping: false,
      avatarIsUploading: false,
      dropzoneOptions: {},
      cropperOptions: {
        aspectRatio: 1,
        dragMode: "move",
        modal: true,
        autoCrop: true,
        viewMode: 3,
        autoCropArea: 1,
        restore: false,
        guides: false,
        center: false,
        highlight: false,
        cropBoxMovable: false,
        cropBoxResizable: false,
        toggleDragModeOnDblclick: false,
        cropstart: function() {

        },
        zoom (e) {
          if (!this.zoomLock) {
            this.zoomLock = true
            if (e.detail.ratio > 1 && this.cropper) {
              e.preventDefault();
              this.cropper.zoomTo(1)
            }
            this.zoomLock = false
          }
        }
      }
    }
  },
  mounted () {
    // this.$store.commit("setUiStates", {
    //   hideMenus: true
    // })
    this.dropzoneOptions = {
      dictDefaultMessage: "Choisissez ou déposez ici votre photo de profil.",
      url: `/api/utilisateurs/${this.currentUser.id}.json?access_token=${this.token}`,
      method: "PUT",
      paramName: 'user[avatar]',
      maxFilesize: 2,
      thumbnailWidth: 600,
      thumbnailHeight: 600,
      thumbnailMethod: 'contain',
      thumbnailQuality: 1.0,
      autoProcessQueue: false
    }
  },
  computed: {
    isBusy () {
      console.log("--->", this.avatarIsUploading, this.avatarIsCropping);
      return this.avatarIsUploading || this.avatarIsCropping
    },
    ...mapState({
      currentUser: "currentUser",
      token: "token"
    })
  },
  methods: {
    onThumbnailGenerated: function(thumbnail, dataURL) {
      this.avatarFile = thumbnail
      this.avatarFileDataURL = dataURL
      this.$nextTick(() => {
        this.avatarIsCropping = true
        console.log("setting avatarIsCropping", this.avatarIsCropping);
        this.cropper = new Cropper(this.$refs.cropper, this.cropperOptions)
      })
    },

    onSuccess (file, user) {
      this.$store.commit("setCurrentUser", user);
      this.avatarIsUploading = false;
      this.avatarIsCropping = false
      this.cropper?.destroy()
      this.cropper = null
      this.avatarFileDataURL = null
      this.avatarFile = null
      this.$refs.avatarVueDropzone.removeAllFiles()
      this.$nextTick(() => {
        this.$emit("onUserAvatarSaved", user)
      })
    },
    cropAndUpload () {
      if (this.cropper) {
        this.cropper.getCroppedCanvas().toBlob(((blob) => {
          this.$nextTick(function(){

            if (this.$refs.avatarVueDropzone) {
              console.log("setting avatarIsUploading", this.avatarIsUploading);
              this.avatarIsUploading = true
              this.$refs.avatarVueDropzone.removeAllFiles()
              this.$refs.avatarVueDropzone.addFile(blob)
              this.$refs.avatarVueDropzone.processQueue()
              this.avatarFile = null
              this.avatarFileDataURL = null
            }
          })
        }).bind(this))
      }
    }
  }
})
</script>
<style lang="scss" scoped>
.crop-button {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  margin-top: -20px;
  margin-left: -20px;
}
</style>
