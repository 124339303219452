<template>
  <v-container fluid fill-height>
    <v-flex xs12 sm8 offset-sm2 md6 offset-md3 class="text-center">
      <router-link to="/">
        <img
          class="my-5 animated fadeInDown"
          src="@assets/images/logo-white.svg"
          v-if="true"
        />
      </router-link>
      <v-form ref="form" v-on:submit.prevent="goNext">
        <v-card class="mx-auto" max-width="500">
          <v-card-title class="title font-weight-regular justify-space-between">
            <span>Mot de passe oublié ?</span>
          </v-card-title>

          <v-window v-model="step">
            <v-window-item :value="1">
              <v-card-text>
                <v-text-field
                  label="Mot de passe"
                  type="password"
                  v-model="password"
                  placeholder="Choisissez un nouveau mot de passe"
                  :autofocus="true"
                  :rules="[rules.required, rules.minEightCharacters]"
                  ref="passwordInput"
                ></v-text-field>
                <v-text-field
                  label="Confirmation"
                  type="password"
                  v-model="confirmedPassword"
                  :rules="[
                    rules.required,
                    rules.minEightCharacters,
                    rules.passwordMatch,
                  ]"
                  placeholder="Retapez à nouveau votre mot de passe"
                  v-on:keyup.enter="goNext()"
                ></v-text-field>
                <v-alert type="error" v-if="error">
                  {{ error }}
                </v-alert>
              </v-card-text>
            </v-window-item>

            <v-window-item :value="2">
              <v-card-text>
                <div class="pa-4 text-center">
                  <h3 class="title font-weight-light mb-2">Parfait !</h3>
                  <v-alert type="success">
                    Votre mot de passe a bien été mis à jour.
                  </v-alert>
                  Vous pouvez à présent l'utiliser pour vous connecter.
                </div>
                <!-- <v-text-field
                  label="Mot de passe"
                  type="password"
                  v-model="user.password"
                  :autofocus="'autofocus'"
                  v-on:keyup.enter="goNext()"
                ></v-text-field>
                <span class="caption grey--text text--darken-1">
                  Veuillez saisir un mot de passe.
                </span> -->
              </v-card-text>
            </v-window-item>
          </v-window>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer />
            <v-btn
              v-if="step < 2"
              :loading="isLoading"
              color="primary"
              depressed
              class="next-button"
              @click="updatePassword()"
            >
              Suivant
            </v-btn>
            <v-btn
              v-else
              :loading="isLoading"
              color="primary"
              depressed
              class="next-button"
              @click="redirectToLogin()"
            >
              SE CONNECTER
            </v-btn>
          </v-card-actions>
        </v-card>
        <p class="mb-2 text-center white--text">
          Vous n'avez pas encore de compte ?
          <router-link :to="{ name: 'signup' }">Créez-en un !</router-link>
        </p>
      </v-form>
    </v-flex>
  </v-container>
</template>
<script>
import Vue from "vue";
import { mapState } from "vuex";
import { EventBus } from "../../event-bus.js";
import AboutImg from "@assets/images/about.jpg";

export default Vue.component("adf-users-lost-password", {
  props: {
    passwordToken: {
      type: String,
      // required: true
    },
  },
  data() {
    return {
      step: 1,
      error: null,
      password: null,
      confirmedPassword: null,
      isLoading: false,
      rules: {
        required: (value) => !!value || "Champ obligatoire",
        minEightCharacters: (value) =>
          (value && value.length >= 8) ||
          "Votre mot de passe doit faire 8 caractères au moins",
        passwordMatch: () =>
          this.password === this.confirmedPassword ||
          "Vos mots de passe doivent correspondrent",
      },
    };
  },

  mounted() {
    //do something after mounting vue instance
    this.$store.commit("setUiStates", {
      bodyStyles: {
        background: `url(${AboutImg})`,
        "background-size": "cover",
      },
    });
    this.$nextTick(() => {
      EventBus.$emit("main-wrapper:toggle-blur", true);
    });
  },

  computed: {},
  methods: {
    updatePassword() {
      if (!this.isLoading) {
        this.isLoading = true;
        const passwordUrl = `/utilisateurs/mot-de-passe.json`;
        this.$http
          .put(passwordUrl, {
            user: {
              password: this.password,
              password_confirmation: this.confirmedPassword,
              reset_password_token: this.passwordToken,
            },
          })
          .then((response) => {
            this.isLoading = false;
            this.step++;
          });
      }
    },
    redirectToLogin() {
      this.$router.push({ name: "login" });
    },
  },
});
</script>
