<template>
  <v-list-item
    @click="emitClick"
    :value="song.id"
    rounded
    color="primary"
    class="song-item-container pa-0"
    :style="{ maxWidth: maxWidthAsString }"
  >
    <v-list-item-icon class="custom-list-icon">
      <v-chip
        :class="`chord-chip ${song.favoriteKey ? 'yellow darken-1' : ''}`"
      >
        <span v-if="song.favoriteKey">
          {{ displayKey(song.favoriteKey) }}
        </span>
        <span v-else-if="song.lastKey">
          {{ displayKey(song.lastKey) }}
        </span>
        <span v-else>
          {{ displayKey(song.key) }}
        </span>
      </v-chip>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>
        <span class="song-title">
          {{ song.title }}
        </span>
        <span class="author-info" v-if="String.isPresent(song.author)">
          - {{ song.author }}
        </span>
      </v-list-item-title>
      <v-list-item-subtitle>{{ song.sneakPeek }}</v-list-item-subtitle>
    </v-list-item-content>
    <slot
      name="append-action"
      :is-selected="isSelected"
      :song-set-preference="song.songSetPreference"
    ></slot>
  </v-list-item>
</template>
<script>
import Vue from "vue";
export default Vue.component("adf-songs-item", {
  props: {
    song: {
      type: Object,
      required: true,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    displayKey: (key) => key?.split("/")[0],
    emitClick() {
      this.$emit("click", this.song);
    },
  },
  computed: {
    maxWidthAsString() {
      return this.maxWidth ? `${this.maxWidth}px` : null;
    },
  },
});
</script>
<style lang="scss" scoped>
.v-application--is-ltr .v-list-item__icon:first-child.custom-list-icon {
  margin-right: 15px;
}
.song-item-container {
  max-width: var(--maxWidth) px;
  font-size: 14px;
}
.chord-chip {
  padding: 0;
  width: 32px;
  text-align: center;
  display: inline-block;
}
.author-info {
  font-style: italic;
  font-size: 14px;
  color: #848484;
}
.song-title {
  font-size: 16px;
}
</style>
