<template>
  <v-container fill-height>
    <v-row no-gutters style="flex-wrap: nowrap;" justify="center">
      <v-col sm="12" md="10" lg="8">
        <v-row align="center" no-gutters>
          <v-col cols="12" :order="getLogoOrder" class="text-center">
            <img
              class="adf-logo animated fadeInUp mb-10 mb-md-0"
              src="@assets/images/logo-big.svg"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            class="text-center pr-md-5 animated fadeInUp"
          >
            <v-btn color="white" rounded :to="{ name: 'login' }" class="" text>
              SE CONNECTER
            </v-btn>
            <v-btn
              color="primary"
              :to="{ name: 'signup' }"
              class="headline subscribe-btn"
              rounded
              x-large
            >
              S'INSCRIRE
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!--<router-link :to="{ name: 'login'}">SE CONNECTER</router-link> -->
  </v-container>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import AboutImg from "@assets/images/about.jpg";

export default Vue.component("adf-about", {
  data: () => ({}),
  mounted() {
    console.log("hiding menus");
    setTimeout(() => {
      this.$store.commit("setUiStates", {
        bodyStyles: {
          background: `url(${AboutImg})`,
          "background-size": "cover",
          "background-position": "bottom",
        },
        hideMenus: true,
      });
    }, 200);
  },
  beforeRouterEnter(to, from, next) {
    next((vm) => {
      console.log("hiding menus");
      vm.$store.commit("setUiStates", {
        bodyStyles: {
          background: `url(${AboutImg})`,
          "background-size": "cover",
          "background-position": "bottom",
        },
        hideMenus: true,
      });
    });
  },
  beforeDestroy() {
    this.$store.commit("setUiStates", { bodyStyles: {} });
  },
  methods: {},
  computed: {
    getLogoOrder() {
      return this.$vuetify.breakpoint.smAndDown ? "first" : "last";
    },
  },
});
</script>

<style scoped lang="scss">
.adf-logo {
  width: 100%;
  max-width: 330px;
}
.main-title {
  color: white;
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;

  &.line-1,
  &.line-2 {
    font-weight: 100;
  }
  &.line-1 {
    font-size: 24px;
  }
  &.line-2 {
    font-size: 32px;
    letter-spacing: 2px;
  }
}
@media (max-width: 424px) {
  .main-title {
    color: white;
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;

    &.line-1,
    &.line-2 {
      font-weight: 100;
    }
    &.line-1 {
      font-size: 17.5px;
    }
    &.line-2 {
      font-size: 22px;
    }
  }
}
@media (max-width: 320px) {
  .subscribe-btn {
    padding: 0px 12px !important;
    height: 37px !important;
    font-size: 21px !important;
  }
}
.about-container {
  background: url(@assets/images/about.jpg) no-repeat center;
  background-size: cover;
}
</style>
