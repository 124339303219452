<template>
  <v-card class="my-4">
    <v-toolbar flat class="mb-4 pt-2">
      <v-avatar class="mr-3">
        <img
        :src="activity.owner.avatar.thumb"
        alt="avatar">
      </v-avatar>
      <v-toolbar-title>
        {{activity.owner.fullName}}
        <div class="body-2">{{[activity.updatedAt, "DD/MM/YYYY HH:mm"] | moment("from")}}</div>
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-menu
        transition="slide-x-transition"
        bottom
        left
        offset-y
      >
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-if="activity.policy.destroy"
            @click="destroy()"
          >
            <v-list-item-title color="danger">SUPPRIMER</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>

    <!-- <v-list-item>
      <v-list-item-avatar color="grey">
        <img
          :src="activity.owner.avatar.thumb"
          alt="avatar">
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="headline">{{activity.owner.fullName}}</v-list-item-title>
        <v-list-item-subtitle>{{[activity.updatedAt, "DD/MM/YYYY HH:mm"] | moment("from")}}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item> -->

    <v-responsive :aspect-ratio="activity.ratio" style="min-height:150px">
      <v-card-text class="activity-content" v-html="activity.content">
      </v-card-text>
    </v-responsive>
  </v-card>
</template>
<script>
export default {
  name: "adf-activity",
  props: {
    activity: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
    }
  },
  methods: {
    destroy() {
      this.$http.delete(`/api/activites/${this.activity.id}.json`)
      .then(() => this.implode())
    },
    implode() {
      this.$emit("destroyed", this.activity)
    }
  }
}
</script>
<style lang="scss" scoped>
.activity-content {
  min-height: 200px;
}
</style>
