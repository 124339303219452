<template>
  <div class="pb-5" v-if="anyAlerts">
    <v-alert v-model="innerAlerts.info" dismissible type="success">
      {{ alerts.info }}
    </v-alert>
    <v-alert v-model="innerAlerts.warning" dismissible type="warning">
      {{ alerts.warning }}
    </v-alert>
    <v-alert v-model="innerAlerts.error" dismissible type="error">
      {{ alerts.error }}
    </v-alert>
  </div>
</template>

<script>
import {mapState, mapGetters} from "vuex"
import {filterObject} from "../../helpers/object";
export default {
  name: "adf-alerts",
  computed: {
    anyAlerts() {
      return Object.values(this.alerts).filter(a => !!a).length > 0
    },
    ...mapState({
      alerts: "alerts"
    }),
  },
  data() {
    console.log("alerts are", this.alerts);
    const alertTypes = ['error', 'info', 'warning']
    return {
      innerAlerts: alertTypes.reduce((alerts, alertType) => {
        alerts[alertType] = false
        return alerts
      }, {})
    }
  },
  mounted() {
    this.setInnerAlerts(this.alerts);
  },
  methods: {
    setInnerAlerts(alerts) {
      Object.keys(alerts).forEach(alertType => {
        this.innerAlerts[alertType] = !!alerts[alertType]
      })
    }
  },
  watch: {
    alerts: {
      handler(alerts) {
        console.log("alertMes", alerts);
        this.setInnerAlerts(alerts)
      },
      deep: true
    },
    innerAlerts: {
      handler(innerAlerts, innerAlertsWas) {
        const falsyAlerts = filterObject(innerAlerts, (alertType, alertValue) => {
          return !alertValue
        })
        if (Object.values(falsyAlerts).length > 0)
          this.$store.commit("setAlerts", {
            ...this.alerts,
            ...falsyAlerts
          })
      },
      deep: true
    }
  }
};
</script>

<style></style>
