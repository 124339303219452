<template>
  <v-list-item-group
    v-model="internalCurrentSongPreferenceId"
    mandatory
    id="song-set-preferences"
  >
    <draggable
      v-model="internalSongSetPreferences"
      group="songSetPreferences"
      @start="dragging = true"
      @end="dragging = false"
      :move="onDragged"
      :disabled="!editMode"
    >
      <transition-group
        type="transition"
        :name="!dragging ? 'flip-list' : null"
      >
        <v-list-item
          v-for="(songSetPreference, i) in songSetPreferences"
          :key="songSetPreference.id"
          :value="songSetPreference.id"
        >
          <v-list-item-content>
            <div class="d-flex song-set-item">
              <!-- <v-icon class="pr-0 mr-0">mdi-dots-vertical</v-icon> -->
              <div
                :class="
                  `d-flex justify-center align-center content ${
                    editMode ? 'clipped' : ''
                  }`
                "
              >
                <v-chip
                  :class="
                    `mr-2 chord-chip ${
                      songSetPreference.song.favoriteKey ==
                      songSetPreference.key
                        ? 'yellow darken-1'
                        : ''
                    }`
                  "
                >
                  {{ songSetPreference.key }}
                </v-chip>
                <span
                  :class="
                    `text-truncate song-title ${editMode ? 'max-150' : ''}`
                  "
                >
                  {{ i + 1 }}.
                  {{ songSetPreference.song.title }}
                </span>
              </div>
              <v-spacer></v-spacer>
              <transition name="slide-fade">
                <adf-song-set-preferences-destroy
                  :song-set-preference="songSetPreference"
                  @destroyed="
                    onSongSetPreferenceDestroyed(songSetPreference, i)
                  "
                  v-if="editMode"
                />

                <!-- <v-icon small  @click="destroySongSetPreference(songSetPreference, i)">
                            mdi-minus-circle-outline
                          </v-icon> -->
              </transition>
            </div>
          </v-list-item-content>
        </v-list-item>
      </transition-group>
    </draggable>
  </v-list-item-group>
</template>

<script>
import draggable from "vuedraggable";
import debounce from "debounce";
import adfSongSetPreferencesDestroy from "../song_set_preferences/destroy.vue";
let internalLock = false;
export default {
  name: "adf-song-sets-set",
  components: {
    draggable,
    adfSongSetPreferencesDestroy,
  },
  props: {
    currentSongPreferenceId: {
      type: Number,
      required: true,
    },
    songSetPreferences: {
      type: Array,
      required: true,
    },
    editMode: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      dragging: false,
      internalCurrentSongPreferenceId: 0,
      internalSongSetPreferences: [],
    };
  },
  mounted() {
    this.internalCurrentSongPreferenceId = this.currentSongPreferenceId;
    this.internalSongSetPreferences = this.songSetPreferences;
  },
  methods: {
    onSongSetPreferenceDestroyed(songSetPreference, i) {
      this.$emit("destroyed", songSetPreference);
    },
    onDragged: debounce(function(e) {
      let moveTo = e.draggedContext.futureIndex + 1;
      let songSetPreference = e.draggedContext.element;
      this.moveSongSetPreference(songSetPreference, moveTo);
    }, 800),
    moveSongSetPreference(songSetPreference, moveTo) {
      this.$emit("move", {
        id: songSetPreference.id,
        attributes: {
          position: moveTo,
        },
      });
    },
  },
  watch: {
    songSetPreferences(songSetPreferences, songSetPreferencesWas) {
      if (songSetPreferencesWas != songSetPreferences) internalLock = true;
      this.internalSongSetPreferences = songSetPreferences;
      this.$nextTick(() => {
        internalLock = false;
      });
    },
    currentSongPreferenceId(
      currentSongPreferenceId,
      currentSongPreferenceIdWas
    ) {
      if (currentSongPreferenceId != currentSongPreferenceIdWas) {
        internalLock = true;
        this.internalCurrentSongPreferenceId = currentSongPreferenceId;
        this.$nextTick(() => {
          internalLock = false;
        });
      }
    },
    internalCurrentSongPreferenceId(currentSongPreferenceId) {
      if (!internalLock)
        this.$emit("update:currentSongPreferenceId", currentSongPreferenceId);
    },
    internalSongSetPreferences(songSetPreferences) {
      if (!internalLock) {
        this.$emit("update:songSetPreferences", songSetPreferences);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.content,
.song-set-item {
  max-width: 100%;
}
.song-set-item {
  .song-title.max-150 {
    max-width: 150px;
  }
}
</style>
